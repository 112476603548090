.background-app{
  background-color: #f7f7f2;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: auto;

  .custom-app {
    margin-bottom: 100px;
  }
}