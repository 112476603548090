@import '../../variables.scss';

.toggle-switch-wrapper {
  .form-check.form-switch {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    margin: 0;
    padding: 0;

    .form-check-label {
      font: 14px MontserratRegular, sans-serif;
      line-height: 32px;
      float: left;
      color: $color-grey;
      cursor: pointer;
    }

    #events-switch {
      background-color: $color-grey;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-size: 30px 50px;
      background-position-x: -3px;
      padding: 0 !important;
      border: none !important;
      box-shadow: none !important;
      width: 46px;
      height: 23px;
      // float: right;
      margin: 0 0 0 10px;
      cursor: pointer;

      &:checked {
        background-color: $color-primary;
        background-position-x: 19px !important;
        border: none;
      }
    }
  }
}