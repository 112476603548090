@import '../../variables.scss';

.timeline {
  width: auto;
}

.display-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.timeline-name {
  margin-left: 10px;
}

.timeline-ipi-text {
  font: 14px MontserratMedium, sans-serif;
  line-height: 17px;
  color: $color-grey;
  padding: 0 15px;
  vertical-align: text-top;
  margin-top: 2px;

  @media(max-width: 575px) {
    font: 12px MontserratMedium, sans-serif;
  }
}

.timeline-ipi-icon {
  margin-left: 2px;
}


.role-label {
  display: flex;
  justify-content: flex-end;
  vertical-align: top !important;
  flex-wrap: wrap;
  flex-grow: 0;
  overflow-wrap: break-word;

  & div {
    text-align: right;
    font: 14px MontserratRegular !important;
    line-height: 17px;
    letter-spacing: -0.2px !important;
    color: $color-grey
  }
}

@media (max-width: ($layout-breakpoint-small)) {

  .role-label {
    flex-wrap: wrap;
    flex-grow: 0;

    & h5 {
      font: 12px MontserratRegular !important;
      line-height: 17px;
      letter-spacing: -0.2px !important;
    }

  }
}