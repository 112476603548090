.bg {
  background: url("../../assets/img/bg-landing-page.png") no-repeat bottom right fixed;
  background-size: contain;
  position: fixed;
  top: 0;
  right: 0;
  width: 700px;
  min-height: 100%;
  // z-index: 100;
}

.public-home {
  display: flex;
  flex-flow: column;
  height: 100vh;

  .top {
    background: linear-gradient(270deg, #8875CA 0%, #9BBBF7 52.08%, #B9D8DE 100%);
    padding: 20px 20px 85px 20px;
    flex: 1 1 auto;

    .logo {
      background: url("../../assets/img/logo-landing-page.svg") no-repeat;
      width: 270px;
      height: 150px;
    }

    .welcome {
      font: 24px PoppinsRegular;
      letter-spacing: 1px;
      line-height: 64px;
      text-transform: uppercase;
      color: #3f3f3f;
    }

    .portal {
      font: 75px PoppinsBold;
      line-height: 80px;
      text-transform: uppercase;
      color: #3f3f3f;
    }
  }

  .bottom {
    background: linear-gradient(178deg, rgba(18, 19, 117, 0.75) 0%, rgba(139, 129, 211, 0.75) 100%);
    padding: 52px 0 35px 0;
    color: #fff;
    flex: 0 1 300px;

    .address, .cookies {
      font: 16px MontserratMedium, sans-serif;
      margin: 10px 0;

      a {
        color: #fff !important;
        text-transform: none;
        font: 16px MontserratMedium, sans-serif;
      }
    }
    .phone {
      background: url("../../assets/img/icon-phone.svg") no-repeat;
      padding: 0 0 0 34px;
      font: 16px MontserratSemiBold, sans-serif;
    }
    .social-networks {
      margin: 50px 0;
      display: flex;

      a.soc-net {
        display: inline-block;
        width: 36px;
        height: 36px;
        margin-right: 20px;
        border-radius: 50px;
        background-color: rgba(44, 46, 54, 0.55);
        background-repeat: no-repeat;
        background-position: center center;

        &.instagram { background-image: url("../../assets/img/icon-instagram.svg") }
        &.youtube { background-image: url("../../assets/img/icon-youtube.svg") }
        &.twitter { background-image: url("../../assets/img/icon-twitter.svg") }
        &.facebook { background-image: url("../../assets/img/icon-facebook.svg") }
        &.dailymotion { background-image: url("../../assets/img/icon-dailymotion.svg") }

        &:hover {
          background-color: #fff;

          &.instagram { background-image: url("../../assets/img/icon-instagram-active.svg") }
          &.youtube { background-image: url("../../assets/img/icon-youtube-active.svg") }
          &.twitter { background-image: url("../../assets/img/icon-twitter-active.svg") }
          &.facebook { background-image: url("../../assets/img/icon-facebook-active.svg") }
          &.dailymotion { background-image: url("../../assets/img/icon-dailymotion-active.svg") }
        }
      }

      .register {
        position: relative;
        .register-btn {
          font: 16px MontserratSemiBold, sans-serif;
          line-height: 24px;
          padding: 6px 12px;
          width: auto;
          color: #fff !important;
          text-transform: none;
          margin-right: 0;
          // background: #545a8b;
          background: rgba(44, 46, 54, 0.55);
          border-radius: 50px;
          border: 0;

          &:hover {
            background: #fff;
            color: #433285 !important;
          }

          &.active {
            border-radius: 50px 0 100px 50px;
            background: #fff;
            color: #433285 !important;
          }
        }

        .info {
          padding: 20px;
          width: 530px;
          font: 14px MontserratRegular, sans-serif;
          line-height: 21px;
          color: #3f3f3f;
          background: #fff;
          border-radius: 50px 50px 50px 0;
          position: absolute;
          top: -82px;
          left: 100%;
          display: none;

          &.open {
            display: block;
          }
        }
      }
    }
  }
}
