@import "../../variables.scss";

.exception-page-container {
  max-width: 700px !important;
  padding-inline: 40px !important;
  text-align: center;
  background: #F7F7F2;
  border-radius: 15px;
  padding: 40px;
  box-shadow: 0 16px 48px 0 #0000002D;;
  &.mt-8 {
    margin-top: 6rem !important;
  }
  h2 {
    font-family: MontserratBold, serif;
    font-size: 34px;
    font-weight: 700;
    line-height: 42px;
    padding: 8px 0;
  }
  .error-code p{
    font-family: MontserratLight, serif;
    font-size: 16px;
    font-weight: 300;
    color: #606060;
  }
  div {
    font-family: MontserratLight, serif;
    font-size: 24px;
    color: black;
  }

  .exception-page-header {
    vertical-align: middle;
    border-radius: 15px 15px 0 0;
    color: #125FE2;
  }

  .redirect-button{
    font: 16px Montserrat, sans-serif;
    font-weight: 600;
    width: 100%;
    margin-block: 20px;
    margin-inline: 10px;
    background: #725FBF;
    border-radius: 100px;
    color: #fff;
    text-transform: uppercase;
    padding: 6px 12px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.13);
    border: none;
    &:hover {
      background: linear-gradient(90deg, #8877CB 0%, #141675 100%);
      border: none;
      color: $color-white;
    }

  }
  .error-msg-box {
    p {
      font-family: MontserratRegular, sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: #2C2E36;
      letter-spacing: 1px;
      line-height: 42px;
    }
    p > p {
      font-family: MontserratRegular, sans-serif;
      padding-top: 20px;
      font-weight: 700;
      color: #2C2E36;
    }
    .back {
      margin: auto;
      width: 90px;
      justify-content: center;
      cursor: pointer;
      font-family: MontserratSemiBold, sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 25px;
      &:hover {
        background:  url(/assets/img/arrow-left-darck.svg) no-repeat;
        transform: translateX(-2px);
      }
    }
  }

}


