@import "../../../../variables.scss";

.primary-text{
  flex-grow: 0;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  text-align: left;
}

.setListCard {
  height: 64px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 13px 20px;
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.13);
  background-color: #fff;
  margin-bottom: 10px;

  label{
    @extend .primary-text;
    font-family: MontserratSemiBold, sans-serif;
    font-size: 16px;
    color: $color-grey-dark;
  }

  .setLists-id{
    @extend .primary-text;
    font-family: MontserratSemiBold, sans-serif;
    font-size: 14px;
    color: $color-grey;
    margin-right: 10px;
  }

  .icon-calendar{
    background: url(/assets/img/icon-calendar.svg) no-repeat center;
    width: 35px;
    height: 35px;
    cursor: pointer;
    &:hover{
      background: rgba(0,0,0,0.1) url(/assets/img/icon-calendar.svg) no-repeat center ;
      border-radius: 100%;
    }
  }
  .icon-files{
    background: url(/assets/img/icon-files.svg) no-repeat center;
    width: 35px;
    height: 35px;
    cursor: pointer;
    &:hover{
      background: rgba(0,0,0,0.1) url(/assets/img/icon-files.svg) no-repeat center ;
      border-radius: 100%;
    }
  }
  .arrow-right-dark{
    background: url(/assets/img/arrow-right-dark.svg) no-repeat center;
    width: 35px;
    height: 35px;
    cursor: pointer;
    &:hover{
      background: rgba(0,0,0,0.1) url(/assets/img/arrow-right-dark.svg) no-repeat center ;
      border-radius: 100%;
    }
  }
}