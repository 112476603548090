@import './../../variables';

.page-status {
  .alert-loading {
    padding: 175px 120px 120px;
    background-image: url('../../assets/img/iconsearch/iconeloading.png');
    background-repeat: no-repeat;
    background-position: center;
    animation: rotation 1s infinite linear;
  }

  .alert-default {
    padding: 175px 120px 120px;
    text-align: center;
    background-image: url('../../assets/img/iconsearch/iconeloading.png');
    background-repeat: no-repeat;
    background-position: center;
  }

  .alert-error {
    background-image: url('../../assets/img/iconsearch/iconerror.png');
    background-repeat: no-repeat;
    background-position: center;
    padding: 120px;
  }

  .alert-text {
    //font-family: "Avenir95Black";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
  }

  .alert-text p {
    text-align: center;
  }

  .test-trans-css {
    color: $color-denim;
  }

  .error-msg-title-trad {
    font-size: 23px;
  }
}

