.distribution-content {
  .distributions-territories-map-footer-row {
    background: white;
    border-top: 1px solid grey;
    font-family: Klamp205Medium, sans-serif;
  }

  .map-legend-container-distributions {
    display: flex;
    padding: 12px 50px 0;
    justify-content: center;
  }

  .colored-legend-element-distributions {
    height: 10px;
  }

  .legend-max-value-element-distributions {
    display: flex;
    justify-content: center;
    font-size: 10px;
  }

  .legend-segment-container-distributions {
    width: 10%;
  }
  
  .world-map {
    background: #fff;
    min-height: 310px;
  }
}
