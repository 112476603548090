@import "../../variables.scss";

.error-modal{
  .modal-container{
    background-color: #f5c6cb!important;
  }
  .modal-text{
    color: #721c24;
  }
  &.overlay-modal {
    background-color: rgba(0, 0, 0, 0.1)!important;
  }
}

.overlay-modal{
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.confirmation-modal {
  .modal-header {
    width: 100%;
    border: none;
    padding: 0;
    margin: 0;
    .btn-close {
      background-size: 0.6rem;
      padding: 0.3rem 0.3rem;
      margin: -0.3rem -0.3rem -0.3rem auto;
    }
  }
}
.modal-container {
  position: fixed;
  top:30%;
  left:50%;
  transform: translate(-50%, -50%);
  z-index: 10;

  .modal-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-radius: 7px 7px 0 0;
    // box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.13);
    background-color: $color-white;
    border: 0;

    select {
      font: 14px MontserratRegular, sans-serif;
      line-height: 20px;
      width: 100%;
      border-radius: 4px;
      border: solid 1px $color-text;
      padding: 8px 15px;
      outline: none !important;
      margin-bottom: 20px;
      &::placeholder {
        font: 14px MontserratRegular, sans-serif;
        color: #939392;
      }
      -moz-appearance:none; /* Firefox */
      -webkit-appearance:none; /* Safari and Chrome */
      appearance:none;
      padding-right: 25px;
      background: $color-white url(/assets/img/icon-drop-down-small.svg) 98% center no-repeat;
      &.holder {
        font: 14px MontserratRegular, sans-serif;
        color: #939392;
      }
      option {
        font: 16px MontserratRegular, sans-serif;
        color: $color-dark;
        line-height: 24px;
        padding: 5px 2px;
        &:first-of-type {
          font: 14px MontserratRegular, sans-serif;
          color: #939392;
        }
        &:hover {
          background-color: #F7F7F2 !important;
        }
      }
    }

  }

  .modal-actions {
    display: flex;

    &.ml {
      margin-left: 40px;
    }

    &.mt {
      padding: 12px 20px 8px;
    }

    &.footer-bg {
      background: #F7F7F2;
      border-radius: 0 0 7px 7px;
      justify-content: flex-end;

      .btn-cancel {
        background: transparent !important;
      }
    }

    .btn-cancel {
      font: 14px MontserratMedium, sans-serif;
      background-color: $color-white!important;
      border: none;
      color: $color-dark!important;
      padding: 4px 8px;
      border-radius: 4px;
      margin-right: 20px;

      &:hover {
        background: rgba(44, 46, 54, 0.1)!important;
      }
    }

    .btn-validate {
      font: 14px MontserratMedium, sans-serif;
      background-color: transparent !important;
      border: solid 1px $color-deepKoamaru !important;
      color: $color-deepKoamaru !important;
      padding: 4px 8px;
      border-radius: 4px;

      &:hover {
        background: $color-deepKoamaru !important;
        color: $color-white!important;
      }
    }
  }

  .modal-text {
    font: 16px MontserratMedium, sans-serif;
    line-height: 1.5;
    text-align: left;
    color: $color-dark;
    margin-bottom: 20px;

    p {
      margin-bottom: 0;
    }

    a {
      color: $color-primary;
    }
  }
}
