@import "../../variables.scss";

.btn.button-header {
  color: $color-white;
  border: 1px solid $color-white;
  padding: 0 12px 0 40px;
  border-radius: 4px;
  background: transparent url(/assets/img/icon-plus.svg) 10px center no-repeat;
  font: 16px MontserratMedium, sans-serif;
  height: 36px;
  display: flex;
  align-items: center;

  &:hover {
    background: transparent url(/assets/img/icon-plus.svg) 10px center no-repeat;
    color: $color-white;
  }
}