@import '../../../../variables.scss';

.form-control{
  width:100%!important;
}
.step-three {
  .btn-cancel {
    border: none;
    color: $color-grey;
    font: 14px MontserratMedium, sans-serif;
    background: rgba(255, 255, 255, 0);
    height: 29px;
    width: 65px;
    text-align: center;
  }
  .btn-submit {
    padding: 0 8px;
    border-radius: 4px;
    border: solid 1px #fff;
    color: $color-white;
    font: 14px MontserratMedium, sans-serif;
    background: $color-light-bleu;
    height: 29px;
    text-align: center;
    margin-left: 10px;
  }

  .input-error {
    color: #bc204b;
    font-size: 14px;
  }
  .rbt-input-multi{
    z-index: 3;
    border-radius: 5px;
    padding: 10px 12px !important;
    cursor: pointer;
    border: solid 1px $color-grey;
    display: flex;
    flex-wrap: wrap;
    padding: 4px;
    min-height: 40px;
  }
  .active-input {
    background: $color-grey;
  }
  .rbt-input-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .rbt-token {
    display: flex;
    justify-content: flex-start;
    border: solid 1px $color-grey;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 0 5px;
    margin: 4px;
    align-items: center;
  }
  .form-control {
    flex-grow: 1 !important;
    flex-shrink: 0 !important;
    height: auto;
    display: flex;
    
    flex-direction: column;
  }
  .rbt-token-label {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    border-radius: 1px 0px 0px 1px;
    padding-left: 10px;
  }
  .rbt-close-content{
    display: none;
  }
  .btn-close {
    font-size: xx-small;
    width: 20px;
    height: 20px;
  }
}

.info-Program-impro{
  padding-left: 10px;
  padding-right: 0px;
}


  @media (max-width: ($layout-breakpoint-small)) {
    .info-Program-impro{
      display: none;
    }
  }
  .info-Program-date{
    padding-left: 10px;
    padding-right: 0px;
    padding-top: 5px;
  }


.input-block-autocomplete {
  &.is-invalid {
    .rbt-input {
      border: 1px solid #dc3545 !important;
    }
  }
}

@media (max-width: ($layout-breakpoint-small)) {
  .info-Program-date{
    display: none;
  }
}