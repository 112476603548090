.top-status-bar {
  &.top-bar-error {
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    height: 22px;
    background: #ffd5d5;
    z-index: 1018;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #e75656;
    font-size: 13px;
    position: fixed;
    transition: all 1s linear;
  }

  &.top-bar-success {
    width: fill-available;
    height: 22px;
    background: #abedbb;
    z-index: 1018;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #18b72b;
    font-size: 13px;
    position: fixed;
  }

  &.top-bar-warning {
    width: fill-available;
    height: 22px;
    background: #e7e47c;
    z-index: 1018;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #cdaa02;
    font-size: 13px;
    position: fixed;
  }
}
