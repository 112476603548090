@import "../../variables.scss";

.urights-box{
  &:first-child {
    padding-right: 10px;
  }
  &:last-child {
    padding-left: 10px;
  }
  .box {
    position: relative;
    height: 176px;
    border-radius: 20px;
    background-size: cover !important;
    flex: 1;
    width: 92%;

    &:not(:last-child) {
      margin-right: 30px;
    }

    &.usage-data {
      background: linear-gradient(to right, rgba(18, 95, 226, 0.6), rgba(18, 95, 226, 0.6)),
                  url(../../assets/img/bg-urights-search.png);

    }

    &.kpi {
      background: linear-gradient(to right, rgba(18, 95, 226, 0.6), rgba(18, 95, 226, 0.6)),
                  url(../../assets/img/kpi-bg.png);

    }
    &.distribution-royalties {
      background: linear-gradient(to right, rgba(59, 81, 181, 0.6), rgba(59, 81, 181, 0.6)),
                  url(../../assets/img/distribution-royalties-bg.png);

    }
    &.cognos {
      background: linear-gradient(to right, rgba(114, 95, 191, 0.6), rgba(114, 95, 191, 0.6)),
                  url(../../assets/img/bg-urights-cognos.png);

    }

    a, div.box-navigate {
      position: absolute;
      bottom: 18px;
      left: 20px;
      background-color: $color-white;
      background-repeat: none;
      border: 1px solid $color-denim;
      border-radius: 100px;
      font: 16px MontserratSemiBold, sans-serif;
      color: $color-denim;
      line-height: 24px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 30px;
      height: 46px;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
