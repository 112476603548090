@import "../../variables.scss";


.bg-landing {
  position: absolute;
  left: 56px;
  right: 0;
  bottom: 0px;
  top: 56px;
  background-color: $color-bg;
  background-image: url(/assets/img/homme_casque.png), url(/assets/img/Femme_casque.png);
  background-repeat: no-repeat, no-repeat;
  background-position: left bottom, right bottom;
  .container {
    display: flex;
    flex-direction: column;
    padding: 30px 0 160px 0;
  }

  .landing-blocks-title {
    font: 22px MontserratSemiBold, sans-serif;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .first-block{
    display: flex;
    gap: 30px;

    .card-wrapper {
      flex: 1;
      .header {
        border-radius: 20px 20px 0 0;
        padding: 10px 30px;
        .title {
          font: 18px MontserratSemiBold, sans-serif;
          line-height: 21px;
          color: $color-white;
          text-transform: uppercase;
          height: 44px;
          display: flex;
          align-items: center;
          padding-left: 55px;
        }
      }
      .card-content {
        background: $color-white;
        border-radius: 0 0 20px 20px;
        padding: 30px 30px 30px 80px;
        display: flex;
        justify-content: flex-end;
        gap: 31px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.13);

        .link {
          padding: 10px 20px;
          border-radius: 100px;
          font: 16px Montserrat, sans-serif;
          line-height: 24px;
          text-transform: uppercase;
          text-align: center;

          &.create-setlist {
            background: $color-light-bleu;
            color: $color-white;
            width: 210px;
            &:hover {
              // background: linear-gradient(to left, #15167a 100%, #2f75ee 0%);
              background: linear-gradient(to left, #15167a 0%, #2f75ee 100%);
              color: $color-white;
            }
          }

          &.check-setlists {
            background-color: $color-white;
            color: #125fe2;
            border: solid 1px #125fe2;
            width: 210px;
            &:hover {
              background-color: #558ef1;
              color: $color-white;
              border-color: #558ef1;
            }
          }

          &.check-events {
            background-color: $color-white;
            color: $color-events-purple;
            border: solid 1px $color-events-purple;
            flex-grow: 1;
            &:hover {
              background-color: $color-events-purple;
              color: $color-white;
              border: none;
            }
          }
        }
      }

      &.setlists {
        .header {
          background: #125fe2;
          .title {
            background: url(../../assets/img/check-setlists.svg) no-repeat;
          }
        }
      }

      &.events {
        .header {
          background: $color-events-purple;
          .title {
            background: url(../../assets/img/check-events.svg) no-repeat;
          }
        }
      }

      .info {
        width: 46px;
        height: 46px;
        background: url(../../assets/img/icon-info-purple.svg) no-repeat;
        background-size: contain;
      }
    }
  }
  .mt-70 {
    margin-top: 70px;
  }
  .second-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 60px;
  }
  .second-block-online {
    border-radius: 10px;
    display: flex;
    height: 96px;
    flex-direction: row;
    justify-content: space-between;
    background-color: #433285;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
  }

  .last-block {
    position: absolute;
    bottom: 40px;
    right: 15%;
    left: 15%;
    display: flex;
    justify-content: space-around;
    margin: 130px auto 0;

    a {
      padding: 0 40px;
      font: 14px MontserratRegular, sans-serif;
      color: $color-dark;
      text-transform: uppercase;
      text-decoration: none;
    }

    .line{
      width: 1px;
      height: 20px;
      transform: rotate(-180deg);
      background-color: $color-grey-dark;
    }
  }
}
