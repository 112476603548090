@import "../../variables";

.switch-background {
  min-height: 100vh;
  background-image: url(/assets/img/Femme_casque_switch.png), linear-gradient(0.25turn, #b9d8de, $color-primary-light, #8875ca);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  padding: 140px 0 0;

  .box-page-container {
    max-width: 920px;
    min-height: 400px;
    margin: auto;
    filter: drop-shadow(0px 0px 0px $color-primary);
    border-radius: 25px;
    display: flex;

    .accounts {
      .title {
        font: 16px MontserratBold, sans-serif;
        line-height: 43px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #2C2E36;
        position: relative;
      }

      .form-content{
        max-height: 170px;
        overflow: auto;
        width: 100%;
      }
    }

    .box-button {
      font-family: MontserratSemiBold, sans-serif;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 6px 12px;
      width:100%;
      border-radius: 100px;
      &.confirm {
        font-size: 16px;
        background: $color-primary;
      }
      &.logout-cancel {
        font-size: 14px;
        background: rgba(238, 238, 238, 1);
        border: none;
        color: $color-grey-dark;
        margin-top: 10px;
      }
    }

    .select-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 60px;
      background: rgba(238, 238, 238, 1);
      border-radius: 15px 0 0 15px;
      width: 50%;
    }

    .background-box {
      background: url(/assets/img/icon-piano-switch.svg) no-repeat;
      background-size: auto;
      border-radius: 0 15px 15px 0;
      width: 50%;

      .sacem-logo {
        background: url(/assets/img/logo-sacem-white.svg);
        width: 180px;
        height: 100px;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    .switch-box-radio {
      margin-top: 15px;
      .form-check-label {
        font: 16px MontserratMedium, sans-serif;
        line-height: 24px;
      }
    }

    .form-check .form-check-input:checked {
      background-color: $color-primary;
      border-color: $color-primary-light;
    }

    .form-check .form-check-input:focus {
      box-shadow: 0 0 0 0;
    }

    .switch-alert-msg {
      font-size: 15px;
      color: $color-primary;
    }
  }
}
