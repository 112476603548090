.usage-data-world-map {
  &.default-map-style {
    height: 500px;
    width: 500px;
    margin: auto;
  }

  .jvectormap-tip {
    //supress map's native stylings
    padding: unset !important;
    border: unset !important;
  }
}

