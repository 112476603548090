@import '../../App.scss';
@import '../../variables.scss';
.content-pagination {
  justify-content: center;
  margin: 0;
}

.page-link {
  color: $color-grey !important;
  height: 100%;
}

.page-item.active .page-link {
  color: $color-white !important;
  background-color: #606060 !important;
  border-color: #606060 !important;
}

.pagination-text {
  font: 14px MontserratRegular, sans-serif;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #606060;
  margin-right: 10px;
}