@import "./../../variables.scss";
@import "bootstrap";

.custom-mantine-table-edit-text-input {
  //filters input style
  input {
    color: aliceblue !important;
  }

  input::placeholder {
    font-size: 13px !important;
  }
}

.mantine-Paper-root {
  border-radius: 7px !important;

  > div:nth-child(2) {
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: #12164a #cad7f4;
    scrollbar-width: thin;
  }
}

.custom-mantine-table-header {
  > div {
    //pushing 3 point icon to the left
    justify-content: space-between !important;
  }

  // header font style
  background: $webso-box-section-background !important;
  color: aliceblue !important;
  line-height: 24px;
  padding-inline: 8px;

  div {
    font-family:
      Open Sans,
      sans-serif;
    font-size: 14px;
    font-weight: 700;
  }
}

.custom-mantine-table-body-cells {
  font-family:
    Open Sans,
    sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  white-space: pre-wrap !important;
  padding: 5px !important;
  height: 48px !important;
}

.custom-mantine-table-top-toolbar {
  min-height: 0 !important;

  > div {
    width: fit-content !important;
  }

  .mantine-ActionIcon-root:not(.mantine-ActionIcon-root ~ .mantine-ActionIcon-root) {
    display: none !important;
  }

  .mantine-ActionIcon-root:nth-child(2) {
    margin-right: 5px;
  }
}

.mantine-Tooltip-tooltip {
  display: none !important;
}

.mantine-ScrollArea-root {
  .mantine-Select-item[data-selected] {
    background-color: #563d7c !important;
    color: white;
  }

  .mantine-Select-item:hover {
    background-color: #ded6eb !important;
    color: #000;
  }
}
