@import '../../variables.scss';
@import "../../fonts.sass";

.header-card {
  background: $color-bg-card;
  color: $color-text;
  font: 18px MontserratRegular, sans-serif;
  line-height: 27px;
  padding: 16px 30px;
  border-bottom: 1px solid $color-border-card;


  @media (max-width: $layout-breakpoint-small) {
    padding: 15px;
    line-height: normal;
  }
}