.login-btn {
  background: linear-gradient(to right, #8877cb 0%, #141675 100%);
  margin-top: 50px;
  width: 330px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  position: relative;
  z-index: 1000;

  &:hover {
    cursor: pointer;

    .login-label {
      background: url("../../../../assets/img/icon-arrow-login-hover.svg") no-repeat;
    }
  }

  span {
    font: 20px MontserratExtraBold;
    line-height: 26px;
    color: #fff;

    &.login-label {
      background: url("../../../../assets/img/icon-arrow-login.svg") no-repeat;
      padding: 0 0 0 40px;
      height: 31px;
      display: flex;
      align-items: center;
    }
  }
}
