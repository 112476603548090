@import "../../variables.scss";

.main-container-artworks {
  //background-color: $color-bg;
  height: 100%;
  padding: 10px 0;
}

img.-icon-back{
    width: 25px;
    height: 25px;
    border: 1px solid $color-dark !important;
    border-radius: 5px;
    padding: 3px 10px;
    margin: 10px 0;
    cursor: pointer;
  }

.header-typical {
  background-color: $color-header-bg;
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid $color-grey;
  padding: 13px 20px;
  margin-bottom: 20px;
  color: $color-dark;
  flex-direction: row;
  display: flex;

  .font-title {
    font: 18px MontserratSemiBold, sans-serif;
    line-height: 27px;
  }
  .header-typical-title {
    font: 18px MontserratSemiBold, sans-serif;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: $color-dark;
    background: url(../../assets/img/icon-setlist.svg) no-repeat;
    min-height: 37px;
    padding-left: 45px;

    @media (max-width: 400px) {
      font-size: 12px;
      margin-left: 10px;
    }
  }
}

.img-icon {
  width: 44px;
  height: 44px;
  object-fit: contain;
}


img.-oeuvre-note {
  flex-grow: 0;
  width: 18px;
  height: 18px;
  margin-bottom: 3px;
  margin-left: -5px;
  align-items: right;
}
