@import "../../variables.scss";
.img-icon{
  width: 30px;
  height: 30px;
}
.primary-title {
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: MontserratLight, sans-serif;
}
.card {
  font-family: MontserratMedium, sans-serif;
  width: 100%;
  .header-card {
    font-size: 18px;
    font-weight: 600;
    font-family: MontserratSemiBold, sans-serif;
  }
  .card-body {
    padding: 1rem 50px;
  }
  .form-label {
    font-size: 16px;
    font-weight: 600;
    font-family: MontserratSemiBold, sans-serif;
  }
  .container-btn {
    display: flex;
    justify-content: flex-end;
    button {
      text-align: center;
      border: none;
      font-size: 20px;
      font-weight: 500;
      padding: 8px 16px;
      border-radius: 4.8px;
      &:first-child {
        margin-right: 20px;
        width: 180px;
        background: $color-white;
        color: $color-grey;
        border: 1px solid $color-grey;
        &:hover {
          box-shadow: 0px 2px 4px #ccc;
        }
      }
      &:last-child {
        color: $color-text;
        width: 125px;
        padding-left: 0;
        background: url(/assets/img/arrow-right-black.svg) $bg-btn-grey no-repeat 85px;
        &.active {
          color: $color-white;
          background: url(/assets/img/arrow-right-white.svg) $color-light-bleu no-repeat 85px;
        }
        &:hover {
          box-shadow: 0 2px 3px #ccc;
        }
      }
    }
  }
  input::placeholder {
    opacity: 0.8;
    color: $color-light-grey;
    font-weight: normal;
    font-family: MontserratRegular, sans-serif;
  }
  .form-control {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.5;
  }
  .search-btn {
    font-size: 20px;
    &:disabled {
      border: solid 1px $color-grey;
      background-color: $color-white;
      color: $color-grey;
    }
  }
}
.shadow-card {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
}
.result-work-count {
  font-family: MontserratSemiBold, sans-serif;
  font-size: 16px;
  text-align: left;
  color: $color-dark;
}

.block-basket {
  padding-top: 63px;
}
.basket-container {
  position: absolute;
  top: 552px;
  right: 10px;
  width: 40%;
  min-height: 60%;

}
.stepper {
  margin-bottom: 30px
}
.error-field {
  color: $color-hub;
  padding: 5px 0;
}

.btn-bg-white {
  background-color: $color-bg;
  border: 1px solid $color-grey;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-family: MontserratMedium, sans-serif;
}

.work-not-found {
  font-size: 14px;
  width: 300px;
  height: 29px;
  display: flex;
  justify-content: center;
  padding: 1px 4px 1px 4px;
  border-radius: 4px;
  border: solid 1px $color-grey;
  background-color: $color-bg;
  color: $color-grey;
  margin-bottom: 15px;
  cursor: pointer;
  &:hover {
    background-color: $color-dark;
    color: $color-white;
  }
}

.works-results {
  .empty-msg {
    font: 16px MontserratBold, sans-serif;
    color: $color-grey;
  }
}

.mb-pagination{
  margin-bottom: 0;
  @media (max-width: ($layout-breakpoint-medium)) {
    margin-bottom: 2rem;
  }

}
.card-box {
  padding: 0;
  border-radius: 6px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 750px;
  height: calc(100% - 30px);
  margin-bottom: 30px;
  @media(max-width: 575px) {
    padding: 0 0 5px;
    margin-top: 10px;
  }

  .tooltip-text {
    font: 14px MontserratRegular, sans-serif;
    line-height: 17px;
    color: #212529;
  }

  .header {
    background-color: $color-header-bg;
    border-radius: 6px 6px 0 0;
    // min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    border-bottom: 1px solid $color-light-grey;

    &.draft {
      background-color: #F0F3F8!important;
      .header-title {
        color: $color-dark;
      }
    }
    .header-title {
      font: 18px MontserratSemiBold, sans-serif;
      line-height: 27px;
      color: $color-dark;
      background: url(/assets/img/icon-setlist.svg) no-repeat;
      padding-left: 45px;
      min-height: 37px;
      display: flex;
      align-items: center;
    }
  }


  img.-oeuvre-note {
    flex-grow: 0;
    width: 18px;
    height: 18px;
    margin-bottom: 3px;
    margin-left: -5px;

  }
  img.-oeuvre-number {
    @extend .img-icon;
    flex-grow: 0;
    width: 20px;
    height: 20px;
    margin-left: 2px;
    margin-top: -10px;
  }
  img.-oeuvre-composer{
    @extend .img-icon;
    flex-grow: 0;
    width: 20px;
    height: 20px;
    margin-top: -10px;
  }
  img.-oeuvre-headphone{
    @extend .img-icon;
    flex-grow: 0;
    width: 30px;
    height: 30px;
    margin-left: -5px;
    margin-top: -10px;
  }
  img.-oeuvre-firstUse{
    @extend .img-icon;
    flex-grow: 0;
    width: 25px;
    height: 25px;
    margin-left: -4px;
    margin-top: -10px;
  }
  img.-oeuvre-euro{
    @extend .img-icon;
    flex-grow: 0;
    width: 21px;
    height: 21px;
    margin-right: 20px;
  }

  .card-body {
    @extend .primary-title;
    padding: 30px 20px 20px 40px;
    color: $color-dark;

    @media(max-width: 575px) {
      padding: 20px 15px;
    }

    & h5 {
      font: 14px MontserratRegular, sans-serif;
      line-height: 17px;
      letter-spacing: -0.2px;
      color: $color-grey;
    }
    & h6 {
      font: 16px MontserratSemiBold, sans-serif;
      line-height: 20px;
      color: $color-dark;
      margin-left: -2px;
    }
    .line-separator {
      margin-top: 10px;
      width: calc(100% - 24px);
    }
  }

  .footer-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .footer-programs {
    margin: 0 20px;
    padding: 15px 10px;
    border-top: 1px dashed $color-light-grey;
    // cursor: pointer;
    justify-content: space-between;

    @media(max-width: 575px) {
      margin: 0 15px;
      padding: 15px 5px;
    }

    @media (max-width: 992px) {
      justify-content: end!important;
    }
    .on-hover{
      width: 35px;
      height: 35px;
      cursor: pointer;
      @media(min-width: 992px){
        &:hover{
          background: rgba(44, 46, 54, 0.1);
          border-radius: 100px;
        }
      }

    }
    .show-rights {
      font: 16px MontserratMedium, sans-serif;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: left;
      color: $color-grey;
      text-decoration: none;
      // background: url(/assets/img/icon-euro.svg) 10px center no-repeat;
      padding: 5px 10px 5px 40px;
      border-radius: 4px;

      &:hover {
        background-color: rgba(44, 46, 54, 0.1);
      }

      @media (max-width: 992px) {
        width: 36px;
        height: 36px;
        margin-right: 10px;
        padding: 0;
        background-position: center;
        span {
          display: none;
        }
      }
    }
    img.-icon-duplicate{
      @extend .img-icon;
      flex-grow: 0;
      width: 36px;
      height: 36px;
      margin-top: -1px;
    }
    img.-icon-calendar-footer {
      @extend .img-icon;
      flex-grow: 0;
      margin-top: 6px;
      margin-left: 7px;
      width: 21px;
      height: 21px;
    }
    img.-arrow-right-small {
      margin-top: 7px;
      margin-left: 11px;
    }

  }
  .footer-drafts {
    margin: 0 20px;
    padding: 15px 10px;
    border-top: 1px dashed $color-light-grey;
    // cursor: pointer;
    .on-hover {
      width: 35px;
      height: 35px;
      cursor: pointer;
      @media(min-width: 992px){
        &:hover{
          background: rgba(44, 46, 54, 0.1);
          border-radius: 100px;
        }
      }
    }
    img.-icon-delete{
      width: 25px;
      height: 25px;
      margin-left: 5px;
    }
    .button-continue {
      @extend .primary-title;
      font-size: 14px;
      background-color: $color-white!important;
      border-color: $color-grey !important;
      color: $color-grey;
      padding: 10px 25px 10px 25px;
      // margin: 0 10px 0 10px;
    }

  }
}
.step-three.card-box {
  width: 100%;
  font-family: MontserratRegular, sans-serif;
  color: $color-dark;
  margin-top: 30px;
  padding: 0;
  .form-check-label, .form-check-input {
    font-family: MontserratMedium, sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: $color-grey-dark;
  }
  .form-check-label {
    margin-top: 1px;
  }
  .form-check-input {
    border: solid 1px #606060;
    border-radius: 2px;
    margin-right: 15px;
    &:checked[type=checkbox] {
      background: url("/assets/img/checkbox-on.svg") no-repeat left center;
      border: none;
    }
  }
  .error-field {
    color: $color-red;
    font-size: 14px;
  }
  .card-body {
    padding: 12px;
  }
  .header {
    display: flex;
    flex-direction: row;
    .badge {
      display: flex;
      height: 78px;
      width: 78px;
      position: absolute;
      z-index: 1;
      margin-top: -6px;
      margin-left: 10px;
      object-fit: contain;
    }

    .header-title {
      font: 18px MontserratSemiBold, sans-serif;
      padding-left: 50px;
      margin-left: 0;
      .error-field {
        font-size: 12px;
        padding: 5px 0;
        font-family: MontserratMedium, sans-serif;
      }
      .works-title {
        border: 1px dashed $color-white;
        border-radius: 4px;
        padding: 0 3px;
        line-height: 1.8;
      }
      .first-item-header {
        margin-top: -6px;
        margin-left: 10px;
      }
    }
    .set-list-title {
      word-break: break-word;
      text-transform: uppercase;
      max-width: 90%;
    }
    .header-btn {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-right: 10px;
      align-items: center;

      .custom-btn-add {
        font-family: MontserratMedium, sans-serif;
        padding: 4px 8px;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        margin-top: 0;
        color: $color-grey-dark;
        &:after {
          content: '+';
          padding-left: 10px;
          font-size: 20px;
        }
      }
    }
  }
  .program-info {
    border: 1px solid $color-border-card;
    border-radius: 6px;
    margin: auto 15px auto auto;
    padding: 0 12px;
    .artwork-title {
      padding: 5px;
    }
    .works-badge {
      width: 65px;
    }
    .basket-work-list-header {
      .works-badge {
        background: url(/assets/img/icon-works-white.svg) no-repeat 8px center #725fbf;
        padding: 16px 12px 15px 0;
        .header-nbr-oeuvre {
          font: 14px MontserratSemiBold, sans-serif;
          padding: 2px 6px;
        }
      }
    }
    .dashed-line {
      border-bottom: 1px dashed #BDC2CC;
      width: 100%;
      margin: 2px 0 15px 0;
    }
    .display-label {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-top: 5px;
    }
    .responsive-pl-3 {
      padding: 30px;
    }
    .m-3-2 {
      margin: 30px 20px;
    }
    .headset {
      background: url("/assets/img/icon-headset-mic.svg") no-repeat left
      center;
      background-size: 26px;
    }
    .title {
      background: url("/assets/img/icon-works-list.svg") no-repeat left
      center;
      background-size: 26px;
    }
    .calendar {
      background: url("/assets/img/icon-calendar-date-of-use.svg")
      no-repeat left center;
      background-size: 26px;
    }
    .star {
      background: url("/assets/img/icon-star.svg") no-repeat left center;
      background-size: 26px;
    }
    .subtitle {
      color: #2c2e36;
      font: 20px MontserratSemiBold, sans-serif;
      text-transform: uppercase;
      padding: 10px;
    }

    .btn-submit {
      margin-right: 0;
    }
    .form-footer {
      margin-top: 20px;
    }
    .text-right {
      text-align: right;
    }
    .line-height-2 {
      line-height: 2.2;
    }
  }

  .card-body-bottom {
    margin: auto;
    justify-content: center;
    padding: 12px 16px;
    border-radius: 4px;
    border: solid 1px #bdc2cc;
    gap: 10px;
    margin: 20px 0;
  }

  .card-footer, form-footer {
    background: none;
    border: none;
    padding: 10px 0;
    .container-btn {
      display: flex;
      justify-content: flex-end;
      padding: 0 40px;
      margin-bottom: 30px;
      button {
        font-family: MontserratMedium, sans-serif;
        text-align: center;
        border: none;
        font-size: 20px;
        font-weight: 500;
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;
        &:first-child {
          margin-right: 20px;
          width: 170px;
          background: $color-white;
          color: $color-dark;
          border: 1px solid $color-text;
          &:hover {
            box-shadow: 0px 2px 4px #ccc;
            background: $color-text;
            color: $color-white;
          }
        }
        &:last-child {
          background: $color-light-bleu;
          color: $color-white;
          &:disabled {
            background: $bg-btn-grey;
            color: $color-text;
            opacity: 1;
          }
          &:hover {
            background: $color-hover-bleu;
          }
        }
      }
    }
  }
  .form-footer {
    .container-btn {
      .btn.btn-primary {
        padding: 5px 10px;
        font: 14px MontserratLight, sans-serif;
        line-height: 1.5;
      }
    }
  }

  .not-checked-icon {
    background: url("/assets/img/icon-check-empty.svg") no-repeat left top;
    background-size: 26px !important;
  }

  .checked-icon {
    background: url("/assets/img/icon-check-full.svg") no-repeat left top;
    background-size: 26px !important;
  }
  .pen {
    background: url("/assets/img/icon-pen-medium.svg") no-repeat right;
    display: flex;
    justify-self: flex-end;
    padding: 20px;
    text-align: right;
  }
  .header-btn {
    .btn {
      background-color: transparent;
      border: 1px solid $color-dark;
      border-radius: 4px;
      color: $color-dark;
      font: 14px MontserratMedium, sans-serif;
      line-height: 21px;
      text-align: center;
      margin-right: 10px;

      @media (max-width: $layout-breakpoint-small) {
        margin-right: 0;
      }
    }
  }


  .icon-showMore {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    align-items: stretch;
    margin: 3px 2px 2px -2px;
    margin-bottom: 10px;
    background: url("/assets/img/icon-arrow-up-small-dark.svg")
    no-repeat left 5px center;
    cursor: pointer;
    &:hover {
      background-color: $color-bg;
    }
    &.rotateIcon {
      transform: rotate(180deg);
    }
  }

  .first-col {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
  }
  .second-col {
    font-family: MontserratRegular, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.2px;
    text-align: right;
    padding-left: 6px;
    padding-top: 3px;
    color: $color-grey
  }

  .pl-3 {
    padding-left: 30px;
  }
  .pr-2 {
    padding-right: 10px;
  }
  .pt-11 {
    padding-top: 11px;
  }
  .name-list {
    display: flex;
    flex-direction:  row;
    padding-top: 10px;
    & h5 {
      text-align: right;
      font: 14px MontserratRegular, sans-serif;
      line-height: 17px;
      letter-spacing: -0.2px;
      color: $color-grey
    }
  }
  .artwork-body {
    padding: 5px 0px 5px 5px;
    .interested-party-title {
      font: 14px MontserratSemiBold, sans-serif;
      flex-wrap: wrap;
      flex-grow: 0;
      line-height: 17px;
      display: inherit;
      align-items: center;
      justify-content: flex-end;
      letter-spacing: -0.2px;
      color: $color-grey;
      order: 0;
      padding: 0px 5px;
      margin: 0px;
    }
    .separator {
      padding: 0;
      margin: 0;
      height: 1px;
      width: 100%;

      border: 1px dashed $color-light-grey;
      transform: matrix(1, 0, 0, -1, 0, 0);
      order: 1;
      display: inline-block;
      vertical-align: middle;
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
  }
}
.popover.custom-popover {
  border: none;
  .popover-body {
    padding: 0;
  }
}
.list-group.custom-list {
  border-radius: 4px !important;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.13);
  border: solid 1px $color-light-grey;
  .list-group-item {
    border: none;
    cursor: pointer;
    &.edit {
      background: url("/assets/img/icon-pen-16x16.svg") no-repeat left
      center;
      padding-left: 20px;
      &:hover {
        background: url("/assets/img/icon-pen-16x16.svg") $color-bg
        no-repeat left center;
      }
    }
    &.delete {
      background: url("/assets/img/icon-trash-16x16.svg") no-repeat left
      center;
      padding-left: 20px;
      &:hover {
        background: url("/assets/img/icon-trash-16x16.svg") $color-bg
        no-repeat left center;
      }
    }
  }
}

.duration-badge {
  width: 84px;
  height: 29px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 20px 4px 10px;
  border-radius: 100px;
  border: solid 1px $color-grey;
  background: $color-white url("/assets/img/icon-clock.svg") no-repeat 58px center;
}
.font-data {
  font: 16px MontserratSemiBold, sans-serif;
  text-align: left;
}
.custom-popover {
  .popover-arrow::before,
  .popover .popover-arrow::after {
    display: none !important;
  }
}
.edit-form {
  margin-bottom: 5px;
}
.empty-data{
  @extend .primary-title;
  text-align: center;
  color: $color-grey;
  margin-top: 10%;
}

.actions {
  display: flex;
  align-items: center;
}
.tp-toast {
  margin: 20px 0;
  padding: 12px 5px 12px 45px;
  border-radius: 4px;
  box-shadow: 0 16px 48px 0 rgba(0, 0, 0, 0.18);
  border: dotted 2px #725fbf;
  background: #f2f3f7 url(/assets/img/icon-ok.svg) no-repeat 10px 14px;
  display: flex;
  justify-content: space-between;
  &.error{
    border: solid 1px #ccbdbd;
    background: #e5dcdc;
  }
  p {
    font: 16px MontserratMedium, sans-serif;
    line-height: 31px;
    color: $color-dark;
    margin: 0 30px 0 0;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      border: 0;
      border-radius: 4px;
      font: 14px MontserratMedium, sans-serif;
      padding: 5px 18px;
    }
    a {
      background: #15167a;
      color: $color-white;
      font: 14px MontserratMedium, sans-serif;
      text-decoration: none;
      padding: 6px 8px;
      border-radius: 4px;
      margin-left: 15px;
      text-align: center;
    }
  }
  .close-btn {
    right: 12px;
    top: calc(50% - 8px);
    width: 16px;
    height: 16px;
    background: url(/assets/img/icon-close.svg) center no-repeat;
    border: none;
  }

  @media(max-width: $layout-breakpoint-small) {
    flex-direction: column;
    padding: 10px 25px 10px 10px;
    p {
      font: 14px MontserratMedium, sans-serif;
      line-height: 24px;
      color: $color-dark;
      margin: 0 0 15px 0;
    }
    .actions {
      button, a {
        font: 13px MontserratMedium, sans-serif;
      }
      button {
        border: 1px solid $color-grey-light;
      }
    }
    .close-btn {
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
}
