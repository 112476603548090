.KPIs-page-container {
  background-color: rgba(0, 0, 0, 0.009);
  .react-datepicker-wrapper {
    border: 1px solid #ced4da;
    border-radius: 100px;
    padding: 0px 30px 0px 0px;
    font:
      14px MontserratRegular,
      sans-serif;
    line-height: 21px;
    color: #939392;
    width: initial;
    background: #e4e7eb url(/assets/img/icon_new_calendar.svg) 96% center no-repeat;
  }
  .mantine-Input-wrapper {
    overflow: unset !important;
  }
  .date-quarter-range {
    border-radius: 9999px 100px 100px 9999px !important;
    background-color: #ffffff !important;
  }
  .react-datepicker__input-container {
    background: #fff;
    border-radius: 9999px 100px 100px 9999px !important;
  }
  .mantine-MultiSelect-input {
    height: 36px;
    border-radius: 80px;
    background: rgba(228, 231, 235, 1) url(/assets/img/arrow-down-full-select.svg) 96% center
      no-repeat !important;
    border: 1px solid #ced4da !important;
  }
  .mantine-6n2p1n {
    padding-left: 0 !important;
  }
  .mantine-MultiSelect-values {
    background: #fff;
    border-radius: 9999px 80px 80px 9999px !important;
    height: 35px;
    border: 1px solid #ced4da;
    border-right: none;
  }
  .list-group-item,
  .tabs-main-container {
    background-color: unset;
  }
  .kpi-reset-btn {
    border: none;
    background-color: rgba(228, 231, 235, 1);
    border-radius: 2px;
    padding: 4px 10px 10px 10px;
  }
  .apply-button {
    border-radius: 6px;
    border: 1px solid rgba(54, 57, 129, 1);
    background-color: rgba(66, 69, 158, 1);
    color: #fff;

    div {
      border: none;
      background-color: unset;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .apply-button:disabled,
  .apply-button[disabled] {
    color: #666;
    border: 1px solid #999;
    background-color: #ccc;
  }
  .mantine-1ejqehl {
    width: 94% !important;
  }

  .mantine-1h28hyu {
    right: 34px !important;
  }
}
