@import "../../variables";

.primary-title {
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.img-icon {
  width: 44px;
  height: 44px;
  object-fit: contain;
}

.right {
  display: flex;
  align-self:flex-end;
  text-align: right;
  margin: 0;
  padding: 0;
  justify-content: flex-end;
  align-content:baseline;
}

.big-card-box {
  padding-bottom: 20px;
  border-radius: 6px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
  background-color: $color-white;
  max-width: 750px;
  height: auto;
  margin-bottom: 15px;
  @media(max-width: 575px){
    padding: 0px 0px 5px;
  }

  .header-works{
    @extend .primary-title;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px;
    padding: 12px 0px 5px 0px;
    border-bottom: 1px dashed #BDC2CC;
    flex: none;
    @media (max-width: 768px) {
      font-size: 16px;
    }
    @media(max-width: 575px){
      margin: 0;
      min-height: 40px;
    }
    .subtitle {
      font: 20px MontserratSemiBold, sans-serif;
      text-transform: uppercase;
      color: $color-dark;
    }
    .badge-oeuvre{
      font: 20px MontserratSemiBold, sans-serif;
      padding: 0px 13px 0px 10px;
      max-width: 80px;
      height: 30px;
      background: $color-primary;
      border: 1px solid $color-primary;
      color: $color-white;
      border-radius: 100px;
      cursor: pointer;
      @media(max-width: 575px) {
        padding: 2px 10px;
        font-size: 12px;
      }
    }
    .header-nbr-oeuvre {
      background-color: $color-white;
      border-radius: 100px;
      padding: 2px 7px;
      margin: 0px 0px 2px 2px;
      max-width: 50px;
      font-size: 14px;
      color: $color-dark;
      min-width: 23px;
      text-align: center;
      @media (max-width: 768px) {
        // font-size: 10px;
        margin: 0;
      }
    }
  }

  .card-body {
    @extend .primary-title;
    margin: 0;
    padding: 0 50px 15px 50px;
    font-weight: normal;
    color: $color-dark;
    line-height: 1.5;
    text-align: left;
    @media (max-width:($layout-breakpoint-small)) {
      padding: 0 25px 15px 25px;
    }

    .card-border {
      border: 1.4px solid $color-grey;
      border-radius: 6px;
      padding: 10px 20px;
      margin-top: 0;
      @media (max-width:($layout-breakpoint-small)) {
        padding: 10px;

        .col-5 {
          padding: 0;
        }
      }
    }

    & h5 {
      font: 16px MontserratSemiBold, sans-serif;
      line-height: 20px;
      color: $color-dark;
      margin-left: -20px;
      text-align: left;
    }

    & h6 {
      font: 14px MontserratRegular, sans-serif;
      letter-spacing: -0.2px;
      color: $color-grey;
    }

    .line-separator {
      margin-top: 10px;
      width: calc(100% - 24px);
    }
  }

  img.-oeuvre {
    @extend .img-icon;
    position: absolute;
    z-index: 2;
    height: 75px;
    width: 75px;
    margin-top: -10px;
    @media (max-width: $layout-breakpoint-medium) {
      height: 65px;
      width: 65px;
    }

  }
  img.-oeuvre-note {
    flex-grow: 0;
    width: 18px;
    height: 18px;
    margin-bottom: 3px;
    margin-left: -5px;
    align-items: revert;

  }
  img.-oeuvre-number {
    @extend .img-icon;
    flex-grow: 0;
    width: 20px;
    height: 20px;
    margin-left: -3px;
    margin-top: -10px;
  }
  img.-oeuvre-composer{
    @extend .img-icon;
    flex-grow: 0;
    width: 20px;
    height: 20px;
    margin-left: -7px;
    margin-top: -10px;
  }
  img.-oeuvre-headphone{
    @extend .img-icon;
    flex-grow: 0;
    width: 30px;
    height: 30px;
    margin-left: -10px;
    margin-top: -10px;
  }
  img.-oeuvre-firstUse{
    @extend .img-icon;
    flex-grow: 0;
    width: 25px;
    height: 25px;
    margin-left: -10px;
    margin-top: -10px;
  }
  img.-oeuvre-micro {
    @extend .img-icon;
    flex-grow: 0;
    width: 20px;
    height: 20px;
    margin-left: -7px;
    margin-top: -10px;
  }
}

@media screen and (max-width: 768px){
  .line-separator{
    border-top: solid 1px #bdc2cc;
    margin-bottom: 10px;
    width: calc(100% - 24px);
    margin-left: 12px;
  }

  .button-detail{
    width: inherit;
  }
}