@import "../../variables";

.distribution-container {
  padding-right: unset !important;
  padding-left: 64px !important;
  height: 97% !important;
  background: $webso-page-content-background;

  .distribution-content {
    max-width: 1500px !important;
    padding: 2vh 6vw;

    .data-filters-reminder {
      height: 100px;
      display: grid;
      align-content: center;
      justify-content: center;
      font-size: 21px;
    }
  }
  .form-check.form-switch {
    display: inline-block !important;
  }

  .box-sizing {
    height: 490px;
  }

  .under-contruction {
    color: #b6b6b6 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 418px;
    width: 100%;
    font-size: 1.5rem;
    background: #fff1f1;
  }

  .distribution-value-box {
    border-radius: 15px;
    height: 100px;
    box-shadow: 0 4px 9px rgba(66, 89, 147, 0.25);
    display: flex;
    background: $webso-box-section-background;
    align-items: center;
  }

  .distribution-value-amount {
    white-space: nowrap;
    display: flex;
    justify-content: center;
    color: white;
    font-size: 30px;
    font-weight: 700;
    padding: 20px;
    width: -webkit-fill-available;
    width: -moz-available;
  }

  .distribution-value-label {
    height: fit-content;
    color: white;
    font-size: 21px;
    padding: 0 1rem;
  }

  .distribution-works-box {
    padding-inline: 5%;
    font-family: "Avenir", sans-serif;

    .distribution-works-box-item {
      display: flex;
      justify-content: space-between;
      height: 34px;
      align-items: center;

      .item-title {
        text-overflow: ellipsis !important;
        white-space: nowrap;
        overflow: hidden;
        width: 60%;
        height: fit-content;
      }

      .item-amount {
        text-align: end;
      }
    }

    .separator {
      border-bottom: 1px solid #0000001c;
      width: 100%;
      justify-self: center;
    }
  }

  .dist-works-tooltip {
    .tooltip-inner {
      max-width: fit-content !important;
    }
  }

  .separator {
    border-bottom: 1px solid #0000001c;
    width: 100%;
    justify-self: center;
  }
  .pt-20 {
    padding-top: 20px;
  }
  .fw-500 {
    font-weight: 500;
  }
  .p-block-13 {
    padding-block: 13px;
  }
  .skeleton-100 {
    height: 100px;
    border-radius: 15px;
  }
  .skeleton {
    height: 100%;
    border-radius: 15px;
  }
}
