@import "../../variables.scss";

.main-container-artworks {
  //background-color: $color-bg;
  height: 100%;
  text-align: center;
  padding: 10px 0;
}
.centered {
  display: flex;
  justify-content: center;
}

.header-representation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $color-card-header;
  padding: 10px 20px;
  margin-bottom: 20px;
  border-radius: 6px 6px 0 0;
  min-height: 61px;
  border-bottom: 1px solid $color-light-grey;

  .date {
    display: flex;
    flex-direction: column;
    font: 18px MontserratBold, sans-serif;
    line-height: 27px;
  }

  .title {
    font: 16px MontserratMedium, sans-serif;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .status {
    font: 12px MontserratSemiBold, sans-serif;
    line-height: 12px;
    color: $color-white;
    background: $color-primary;
    border-radius: 40px;
    padding: 4px 4px;
    max-width: fit-content;

    &.distributed {
      background: #088741;
    }
  }
}

.body-representation {
  .card-line {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 20px;
    .value {
      font: 16px MontserratSemiBold, sans-serif;
      flex: 2 1;
      & > * {
        margin: 0;
      }
    }
    .label {
      font: 14px MontserratRegular, sans-serif;
      letter-spacing: -0.2px;
      color: $color-grey;
      flex: 1;
      margin-right: 15px;
    }
  }
}

@media (max-width: $layout-breakpoint-small) {
  .centered {
    padding-top: 20px;
  }
  .header-representation {
    align-items: flex-start;
    flex-direction: column;
    padding: 10px 15px;

    .status {
      margin-top: 10px;
    }
  }
  .body-representation {
    font: 14px MontserratSemiBold, sans-serif;

    .card-line {
      .value {
        font: 14px MontserratSemiBold, sans-serif;
      }
      .label {
        font: 12px MontserratRegular, sans-serif;
      }
    }
  }
}
