@import "../../../../variables";

.representation-card {
  border-radius: 6px;
  margin-bottom: 30px;
  background: $color-white;
  color: $color-dark;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.13);
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $color-card-header;
    padding: 10px 20px;
    border-radius: 6px 6px 0 0;
    min-height: 61px;
    border-bottom: 1px solid $color-light-grey;

    .date {
      display: flex;
      flex-direction: column;
      font: 18px MontserratBold, sans-serif;
      line-height: 27px;
    }

    .title {
      font: 16px MontserratMedium, sans-serif;
      text-transform: uppercase;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .status {
      font: 12px MontserratSemiBold, sans-serif;
      line-height: 12px;
      color: $color-white;
      background: $color-primary;
      border-radius: 40px;
      padding: 3px 10px;

      &.distributed {
        background: #088741;
      }
    }
  }
  .body {
    padding: 20px 25px;
    .card-line {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .value {
        font: 16px MontserratSemiBold, sans-serif;
        flex: 2 1;
        & > * {
          margin: 0;
        }
      }
      .label {
        font: 14px MontserratRegular, sans-serif;
        flex: 1;
        margin-right: 15px;
      }
    }
  }
  .footer {
    border-radius: 0 0 6px 6px;
    padding: 0 25px;
    height: 50px;
    .actions {
      display: flex;
      padding: 10px 0;
      border-top: 1px dashed #BDC2CC;

      .btn-delete {
        border: none;
        background: none;
        font: 14px MontserratMedium, sans-serif;
        line-height: 21px;
        color: $color-grey;
        border-radius: 4px;
        padding: 4px 8px;

        &:hover {
          background: rgba(44, 46, 54, 0.1);
          color: $color-dark;
        }
      }

      .more-info {
        font: 14px MontserratSemiBold, sans-serif;
        line-height: 21px;
        background: url(/assets/img/arrow-right-black.svg) center right no-repeat;
        text-decoration: none;
        padding-right: 25px;
        display: flex;
        align-items: center;
        color: $color-dark;
      }
    }
  }

  @media(max-width: $layout-breakpoint-small) {
    .header {
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 15px;

      .status {
        margin-top: 10px;
      }
    }

    .body {
      padding: 20px 15px;
    }

    .footer {
      padding: 0 15px;
    }
  }
}