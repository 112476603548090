.tabs-main-container {
  padding: 6px 12px !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 !important;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.14);
}

.tabs-button-container {
  height: 42px;
  border-radius: 100px;
  border: 1px;
  border-color: #5f4389;
  border-style: solid;
  background-color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
}

.tabs-button {
  height: 30px;
  padding: 6px 14px;
  border-radius: 100px;
  border: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}
