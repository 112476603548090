.select-all-offers {
  float: right;
}
.auto-width {
  width: 100%;

  .title {
    width: 100%;
    display: inline-block !important;
    vertical-align: center;
  }

  .title:hover {
    overflow: visible;
  }

  .title::first-letter {
    text-transform: uppercase;
  }
}
