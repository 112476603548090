@import "../../variables.scss";

.artwork-title {
  padding: 5px;
  margin-bottom: 9px;
  background: $color-bg-card;
  border-radius: 6px 6px 0px 0px;
  align-items: center;

  img.-oeuvre-note {
    display: inherit;
    width: 22px;
    height: 22px;
  }

  .work-name {
    font: 16px MontserratSemiBold, sans-serif;
    line-height: 20px;
    padding-left: 0px;
    flex-grow: 3;
  }
  .work-badge {
    display: flex;
    flex-direction: row;
    text-align: right;
    max-width: fit-content;
    justify-content: flex-end;
    margin-left: -15px;

    .icon {
      width: 25px;
      height: 25px;
      object-fit: contain;
      padding-left: 37px;
      margin: 0px 1px;
      border-radius: 4px;

      &.referenced {
        background: url("../../assets/img/icon-note-dark.svg") no-repeat 10px
          center;
      }
      &.non-referenced {
        background: url("../../assets/img/icon-non-referenced.svg") no-repeat
          10px center;
      }
    }
  }
  .icon-three-dots {
    background: url("../../assets/img/icon-three-dots.svg") no-repeat right
      center;
    width: 36px;
    height: 36px;
  }
  .work-numbers {
    font: 14px MontserratMedium, sans-serif;
    line-height: 21px;
    color: $color-grey;
    letter-spacing: 2px;
    display: inherit;
    flex-grow: 0;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}

@media (max-width: ($layout-breakpoint-small)) {
  .artwork-title {
    .work-name {
      font: 14px MontserratSemiBold, sans-serif;
      line-height: 15px;
    }
  }
}
