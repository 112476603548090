@import '../../variables.scss';

.supra-nav {
  background-color: $color-white;
  display: flex;
  border-bottom: 0.5px $color-grey solid;
  justify-content: space-between;
  height: 56px;
  padding: 3px 35px 3px 25px;
  margin-left: 56px;

  .nav-left {
    display: flex;
    align-items: center;
    line-height: 24px;

    .user {
      font-family: MontserratSemiBold, sans-serif;
      font-size: 22px;
    }

    .welcome-message {
      font-size: 18px;
      font-family: MontserratLight, sans-serif;
      color: rgba(33, 37, 41, 0.7);
      border-left: 1px solid rgba(33, 37, 41, 0.7);
      padding-left: 10px;
      margin-left: 10px;
    }
  }

  .nav-right {
    display: flex;
    align-items: center;

    .switch-account {
      font: 14px MontserratSemiBold, sans-serif;
      text-align: center;
      color: #212529;
      background: $color-AthensGray url("../../assets/img/swap_caret.svg") 92% center no-repeat;
      padding: 4px 40px 4px 10px;
      cursor: pointer;
      border: solid 1px $color-AthensGray;
      border-radius: 50px;
    }

    .log-out {
      background: url("../../assets/img/logout.svg") no-repeat;
      width: 24px;
      height: 24px;
      display: inline-block;
      cursor: pointer;
      margin-top: 4px;
      margin-right: 10px;
    }
    .user-connected {
      background: url("../../assets/img/user.svg") no-repeat;
      width: 24px;
      height: 24px;
      display: inline-block;
      cursor: pointer;
      margin-top: 4px;
      margin-right: 10px;
    }
    .account {
      background: url("../../assets/img/profile-circle.svg") no-repeat;
      width: 38px;
      height: 38px;
      display: inline-block;
      cursor: pointer;
    }
    .dropdown-toggle::after {
      display: none;
    }
  }
}

