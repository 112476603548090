.double-axis-legend-item {
  display: flex;
  align-items: center;
  justify-content: center;

  .title-color {
    width: 30px;
    height: 15px;
    border-radius: 10px;
  }

  .title {
    font-family: Avenir, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    line-height: 19px;
    text-overflow: ellipsis;
    overflow: clip;
    display: inline-block !important;
    margin-left: 10px;
  }
}
