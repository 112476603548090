.exploitation-territories-mobile {
    .territory-label {
        font-family: 'avenir';
        padding-top: '15px' ;
    }
    .block-currency {
        display: 'flex';
        justify-content: 'space-between';
        padding-block: '5px';
        .amount {
            width: 'fit-content';
            color: '#495057';
        }
        .percent {
            width: 'fit-content';
            color: '#C41C89';
        }
    }
}