@import '../../variables.scss';
@import "../../fonts.sass";
.back-btn {
  width: 21px;
  height: 21px;
  flex-grow: 0;
  object-fit: contain;
  background: url(/assets/img/icon-arrow-left-back.svg) center no-repeat;
  border:none;
}
.back-btn-div {
  width: 77px;
  height: 29px;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  border: solid 1px #2c2e36;
  display: flex;
  cursor: pointer;

  label{
    width: 36px;
    height: 21px;
    flex-grow: 0;
    font-family: MontserratMedium, sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #2c2e36;
    cursor: pointer;

  }
}
