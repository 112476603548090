@import "../../../../variables.scss";
@import "../../../../fonts";

.primary-text{
  flex-grow: 0;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  text-align: left;
}

.draftCard {
  height: 64px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 13px 20px;
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.13);
  background-color: #fff;
  margin-bottom: 10px;

  label{
    @extend .primary-text;
    font-family: MontserratSemiBold, sans-serif;
    font-size: 16px;
    color: $color-grey-dark;
  }

  .icon-delete{
    background: url(/assets/img/delete-icon.svg) no-repeat center;
    width: 35px;
    height: 35px;
    cursor: pointer;
    &:hover{
      background: rgba(0,0,0,0.1) url(/assets/img/delete-icon.svg) no-repeat center ;
      border-radius: 100%;
    }
  }
  .button-finalize {
    @extend .primary-text;
    font-family: MontserratMedium, sans-serif;
    font-size: 16px;
    background-color: $color-white!important;
    border-color: $color-deepKoamaru !important;
    color: $color-deepKoamaru !important;
    padding: 10px 25px 10px 25px;

    &:hover{
      color: $color-white !important;
      background-color: $color-deepKoamaru!important;

    }
    // margin: 0 10px 0 10px;
  }
}