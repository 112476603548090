.usage-data-container {
  .scroll-table {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: auto;
    scrollbar-color: #12164a #cad7f4;
    scrollbar-width: thin;
  }

  .chart-data-table thead {
    position: sticky;
    top: 40px;
    display: table-header-group;
    margin: 0;
    border-radius: 15px;
  }

  .btn-see-charts {
    background-image: url('./../../assets/img/charts/button_charts_details.svg');
    background-repeat: no-repeat;
    color: #12164a !important;
    border: none !important;
    font-size: small;
    height: 31px;
    width: 31px;
    border-radius: 100px !important;
  }

  .btn-see-charts:hover {
    transform: rotate(45deg);
    transition: all 0.5s ease-in-out;
    color: #222;
    background-color: #adbeec !important;
    border-color: #7cc;
  }

  .charts-container {
    padding-right: unset !important;
    padding-left: 64px !important;
    height: auto !important;
  }

  .header-background-separation {
    .table {
      box-shadow: 0 4px 15px rgba(66, 89, 147, 0.25);
      word-break: break-word;
      width: 100%;
    }

    th {
      width: fit-content;
      color: white;
      font-family: 'Montserrat', serif;
      font-size: 16px;
      background-color: #12164a !important;
      white-space: nowrap !important;
    }
  }

  .charts-container-mobile,
  .charts-container {
    padding-left: 64px !important;
    height: 97%;

    .red-line {
      background-image: url('../../assets/img/charts/red-line.png');
      background-repeat: no-repeat;
      background-position: center;
      padding: 2px;
      content: ' ';
    }

    .black-line {
      text-align: center;
    }
  }

  .rotate-180 {
    transform: rotate(180deg);
  }

  .down-arrow {
    background-image: url('../../assets/img/charts/arrow-down.svg');
    background-repeat: no-repeat;
    border: none;
    width: 15px;
    height: 15px;
    background-color: transparent;
  }
}
