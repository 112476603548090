 .placeholder-select{
  opacity: 1;
}

select:invalid {
    color: #939392 !important;
}
option[value=""][disabled] {
    //display: none;
}
option {
    color: #000;
}