@import 'src/variables';

.work-form {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  color: $color-dark;
  flex: 1;
  margin-top: 10px;
  margin-bottom: 30px;
  .form-header {
    display: flex;
    align-items: start;
    background-color: $color-bg-card;
    padding: 15px 15px 15px 20px;
    font: 18px MontserratSemiBold, sans-serif;
    line-height: 27px;
    color: $color-dark;
    border-radius: 6px 6px 0 0;
  }
  .form-body {
    border-radius: 0 0 6px 6px;
    padding: 40px 50px;
    background: $color-white;
    label {
      font: 16px MontserratSemiBold, sans-serif;
      line-height: 24px;
    }
    .info {
      margin-bottom: 25px;
      margin-top: -15px;
      p {
        font: 12.8px MontserratMedium, sans-serif;
        line-height: 24px;
        color: $color-grey;
        margin: 0;
      }
    }
    .input-block {
      margin-bottom: 25px;
      label {
        font: 16px MontserratSemiBold, sans-serif;
        line-height: 24px;
      }
      input, select {
        font: 20px MontserratMedium, sans-serif;
        line-height: 30px;
        width: 100%;
        border-radius: 5px;
        border: solid 1px $color-grey;
        padding: 8px 15px;
        &.error {
          border: solid 2px $color-red;
          color: $color-dark;
          margin: 0;
        }
        &::placeholder {
          font: 20px MontserratRegular, sans-serif;
          color: #939392;
        }
      }
      .input-error {
        font: 14px MontserratRegular, sans-serif;
        color: $color-red
      }
    }

    .input-block-autocomplete {
      @extend .input-block;
      margin-bottom: 0 !important;
      .close.btn-close.rbt-close.rbt-token-remove-button {
        font-size: xx-small;
        padding-top: 10px;
      }
    }
    .input-error-ipis-code {
      margin-bottom: 25px;
      font: 14px MontserratRegular, sans-serif;
      color: $color-red
    }

    .input-duration {
      margin: 35px 0;
      .input-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;
        &.error {
          border: solid 2px $color-red;
          color: $color-dark;
          margin: 0;
        }
        label {
          font: 16px MontserratSemiBold, sans-serif;
          line-height: 24px;
        }
        input {
          font: 16px MontserratMedium, sans-serif;
          line-height: 21px;
          width: 100px;
          height: 30px;
          border-radius: 100px;
          border: solid 1px $color-grey;
          padding: 8px 25px 8px 12px;
          background: $color-white url(/assets/img/icon-duration.svg) 90% center no-repeat;
          &.error {
            border: solid 2px $color-red;
            color: $color-dark;
            margin: 0;
          }
          &::placeholder {
            font: 16px MontserratRegular, sans-serif;
            color: #939392;
          }
        }
      }
      .input-error {
        font: 14px MontserratRegular, sans-serif;
        color: $color-red
      }
    }

    .rbt-input-multi{
      z-index: 3;
      border-radius: 5px;
      padding: 10px 12px !important;
      cursor: pointer;
      border: solid 1px $color-grey;
      display: flex;
      flex-wrap: wrap;
      padding: 4px;
      min-height: 40px;
    }
    .active-input {
      background: $color-grey;
    }
    .rbt-input-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .rbt-token {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border: solid 1px $color-grey;
      border-radius: 10px;
      margin-bottom: 10px;
      padding: 0 5px;
      margin: 4px;
    }
    .form-control {
      flex-grow: 1 !important;
      flex-shrink: 0 !important;
      height: auto;
      display: flex;
      
      flex-direction: column;
    }
    .rbt-token-label {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      border-radius: 1px 0px 0px 1px;
      padding-left: 10px;
    }
    .rbt-close-content{
      display: none;
    }
    .rbt-close{
      padding-right: 10px;
      height: 20px;
      width: 20px;
    }

  }

  .form-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 50px 40px;
    background: $color-white;

    button {
      font: 20px MontserratMedium, sans-serif;
      line-height: 30px;
      margin-left: 20px;
      padding: 8px 16px;
      border-radius: 5px;

      &.btn-cancel {
        border: 1px solid $color-grey;
        color: $color-grey;
        background: $color-white;
        &:hover {
          background: rgba(44, 46, 54, 0.1)!important;
        }
      }
      &.btn-submit {
        color: $color-white;
        width: 100px;
        text-align: left;
        background: url(/assets/img/icon-add-night-blue-small.svg) no-repeat right $color-light-bleu;
        &:disabled {
          background: url(/assets/img/icon-add-dark-small.svg) no-repeat right rgba(61, 67, 102, 0.1);
          border: $color-grey;
          color: $color-grey;

        }
      }
    }
  }
}

@media (max-width:$layout-breakpoint-small) {
  .work-form {
    .form-body {
      padding: 30px 15px;
    }
  }
}