.mantine-custom-header {
  width: 100%;
  background-color: #fff;
  margin-top: 3rem;
}

.filter-arrow-down {
  background: url("../../assets/img/filters/arrow-down.svg") no-repeat center;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
}

.quarter-arrow-down {
  cursor: pointer;
  border: 0;
  outline: 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
}

.mantine-MultiSelect-value {
  display: none !important;
  max-width: 30% !important;
}

.mantine-MultiSelect-value:nth-child(-n + 2) {
  display: flex !important;
}

.mantine-Input-rightSection {
  .badge-icon {
    background-color: #363981 !important;
  }
}

.mantine-InputWrapper-root {
  input::placeholder {
    color: var(--gray-600-placeholder, #6c757d);
    font-family: Avenir, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
  }
}

.apply-button {
  background-color: #fff;
  border-radius: 50px;
  border: 2px solid #363981;

  div {
    border-radius: 50px;
    background: #363981;
    color: #fff;
    text-align: center;
    font-family: Avenir, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 18.8px;
  }
}

.apply-button:disabled,
.apply-button[disabled] {
  border: 1px solid #999;
  color: #666;

  div {
    border: 1px solid #999;
    background-color: #ccc;
    color: #666;
  }
}

.date-quarter-range:disabled,
.date-quarter-range[disabled] {
  background-color: #f1f3f5;
  opacity: 0.6;
  color: #909296;
}

.date-quarter-range {
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji;
  height: auto;
  -webkit-tap-highlight-color: transparent;
  line-height: 1.55;
  appearance: none;
  resize: none;
  box-sizing: border-box;
  font-size: 0.875rem;
  width: 100%;
  color: #000;
  display: block;
  text-align: left;
  border: 0.0625rem solid #ced4da;
  background-color: #fff;
  transition: border-color 100ms ease !important;
  min-height: 2.25rem !important;
  padding-left: 0.75rem !important;
  padding-right: 2.25rem !important;
  border-radius: 0.25rem !important;
  cursor: pointer !important;
}

.date-month-range:disabled,
.date-month-range[disabled] {
  background-color: #f1f3f5;
  opacity: 0.6;
  color: #909296;
}

.date-month-range {
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji;
  height: auto;
  -webkit-tap-highlight-color: transparent;
  line-height: 1.55;
  appearance: none;
  resize: none;
  box-sizing: border-box;
  font-size: 0.875rem;
  width: 100%;
  color: #000;
  display: block;
  text-align: left;
  border: 0.0625rem solid #ced4da;
  background-color: #fff;
  transition: border-color 100ms ease !important;
  min-height: 2.25rem !important;
  padding-left: 0.75rem !important;
  padding-right: 2.25rem !important;
  border-radius: 0.25rem !important;
  cursor: pointer !important;
}

.react-datepicker__close-icon::after {
  background-color: transparent !important;
  content: url("../../assets/img/filters/clear-icon.svg") !important;
  outline: none;
  opacity: 0.5;
  width: 16px;
  height: 16px;
  padding: 0;
}

.show-more-btn {
  height: 33px;
  border: 0;
  border-radius: 3px;
  background-color: #e4e7eb;
  padding: 1px 1px 5px 7px;
  margin-top: 2px;
  width: 90px;

  span {
    background-position: right;
    background-repeat: no-repeat;
    padding: 5px 20px 5px 0;
    font-size: 12px;
    font-family: Avenir35Light, sans-serif;
  }

  .arrow-down {
    background-image: url("../../assets/img/filters/arrow-down-trans.svg");
  }

  .arrow-up {
    background-image: url("../../assets/img/filters/arrow-up-trans.svg");
  }
}

// .orion-creatable-filter {
//   /* Chrome, Safari, Edge, Opera */
//   /* stylelint-disable */
//   input::-webkit-outer-spin-button,
//   input::-webkit-inner-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
//   }

//   /* Firefox */
//   input[type="number"] {
//     -moz-appearance: textfield;
//   }
//   /* stylelint-enable */
// }

.list-group {
  .mantine-Autocomplete-item[data-selected],
  .mantine-Select-item[data-selected],
  .mantine-MultiSelect-item[data-selected] {
    background-color: #9bbbf7 !important;
    background-image: url("../../assets/img/filters/check.svg");
    background-position-y: center;
    background-position-x: 95%;
    background-repeat: no-repeat;
    padding-right: 50px;
    color: white;
  }

  .mantine-Autocomplete-item[data-hovered],
  .mantine-Autocomplete-item:hover,
  .mantine-Select-item[data-hovered],
  .mantine-Select-item:hover,
  .mantine-MultiSelect-item[data-hovered],
  .mantine-MultiSelect-item:hover {
    background-color: #ded6eb !important;
    color: #000;
  }

  .mantine-Input-input:focus,
  .mantine-Input-input:focus-within {
    border-color: #9bbbf7 !important;
  }
}
.filter-bar {
  align-items: center;
  width: 100%;
  flex-direction: row;
  display: flex;
  border-radius: 0.375rem;
  background-color: white;
  padding: 8px 12px;
  max-height: 50px;
  transition: all 0.4s ease;

  .filter-wrapper {
    flex: 1 1 0px !important;
    width: 0;
    border: none !important;
    padding: 0 4px;
    display: flex;
    // justify-content: center;
    position: relative;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .button-wrapper {
    align-items: center;
    display: flex;
    border: none !important;
    text-align: center;
    padding: 0 4px;

    &:last-child {
      padding-right: 0;
    }
  }
}

.vertical-collapse {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease;
}

.vertical-collapse.show {
  max-height: 100px;
  overflow: visible;
}
