@import "../../variables.scss";

.react-datepicker__input-container input {
    width: 100%;
}
.wrapper-date {
    .react-datepicker-wrapper {
        background: none !important;
        border: 1px solid #ced4da;
        appearance: none;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;;
        padding: 1px 0 !important;;
        font: 14px MontserratRegular, sans-serif;
        line-height: 21px !important;
        color: $color-dark;
        width: 100% !important;
    }
    .react-datepicker-wrapper input {
        border: none !important;
        outline: none !important;
        width: 100%;
        color: $color-dark !important;
    }
    .react-datepicker-wrapper:focus-visible {
        outline: none;
    }
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    label {
        width: 100%;
        text-align: right !important;
    }
    .react-datepicker__triangle {
        left: -50% !important;
    }

    .diffusion-date {
        background: url("../../assets/img/icon-calendar.svg") no-repeat right 15px center !important;
    }
    .diffusion-date:focus-visible {
        outline: none;
    }
}
.calendar-icon {
    position: relative;
    right: 10px;
    top: -34px;
}
