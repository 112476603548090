.works-ranking {
  .spw {
    max-width: 1080px;
    margin: 0 auto;

    .spw-header {
      display: inline-flex;
      background: linear-gradient(90deg, #03051b, #363981);
      border-top: 10px;
      position: relative;
      top: 25px;
      border-radius: 15px 15px 0 0;
      z-index: 22;

      .spw-header-bg {
        background: url('../../assets/img/Ellipse9.svg');
        background-size: cover;
        width: 100%;
        display: inline-flex;
      }

      p {
        font-family: MontserratMedium, sans-serif;
        font-weight: 500;
        font-size: 24px;
        color: white;
        position: relative;
        top: 8px;
      }

      span {
        font-family: MontserratMedium, sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 19px;
        color: white;
        position: relative;
        top: 10px;
      }
    }

    .spw-header-bottom {
      border-radius: 15px;
    }

    .select-all-works {
      float: right;

      #custom-switch-all-providers {
        width: 25px;
        height: 15px;
      }

      #custom-switch-all-providers:checked {
        width: 25px;
        height: 15px;
        margin-top: 5px;
        background-color: #363981;
        border-color: #363981;
      }

      label {
        float: left;
        margin-top: 3px;
        font-family: MontserratMedium, sans-serif;
        font-weight: 800;
        font-size: 13px;
        color: #363981;
      }
    }

    .list-box-works {
      display: inline-flex;

      input {
        width: 25px;
        height: 15px;
      }

      label {
        font-family: MontserratMedium, sans-serif;
        font-weight: 800;
        font-size: 13px;
        color: #363981;
        margin-top: 7px;
        margin-left: 5px;
      }
    }

    .spw-chart {
      background: linear-gradient(90deg, #e5eaf5 81.78%, #fff 99.21%);
      position: relative;

      // width: 800px;
      // height: 650px;
    }
  }

  .ms-2 {
    margin-left: 0.5rem !important;
  }

  .custom-item-searched {
    .rounded-pill {
      border-radius: 80rem !important;
      //width: 30px;
    }

  }

  .custom-justify {
    justify-content: center;
  }

  @media (max-width: 1024px) {
    .spw .form-check {
      display: block;
    }
  }

  .spw .form-check .dsp p {
    width: 240px;
  }

  @media (max-width: 420px) {
    .spw .form-check .dsp p {
      width: 240px;
    }

    .spw .select-all-providers label {
      width: 120px;
    }

    .spw .spw-header p {
      font-size: 19px;
    }

    .spw .form-check .dsp .list-box-dsp {
      display: inline-block;
    }
  }

  .white-bg {
    background-color: #fff;
  }

  .artists-list {
    margin-bottom: 0;

    li {
      font-family: MontserratMedium, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
    }

    li::first-letter {
      text-transform: uppercase;
    }
  }

  .bf {
    font-family: MontserratBold, sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: #363981;
    letter-spacing: -0.05em;
  }

  .btn-resource-id {
    border-radius: 40px !important;
    color: #363981 !important;
    border-color: #363981 !important;
    font-family: MontserratMedium, sans-serif !important;
    font-style: normal;
    font-weight: 800 !important;
    font-size: 13px !important;
    line-height: 19px !important;
  }

  .btn-resource-id:hover {
    color: #222;
    background-color: #adbeec !important;
    border-color: #7cc;
  }

  .title64 {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }

  .bottom-border {
    border-radius: 0 0 15px 15px;
  }

  .tooltip-title {
    text-transform: capitalize;
    border-radius: 15px;
    padding: 0.5em;
    font-family: MontserratBold, sans-serif;
    font-weight: 800;
    font-size: 13px;
    color: #fff;
    background: linear-gradient(180deg, #03051b 0%, #363981 100%);
  }

  .bordered {
    border: 1px solid rgba(211, 211, 211, 0.636);
  }

  /* new css */
  .usage-data-legends {
    text-transform: lowercase !important;

    .title {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 13px;
      line-height: 19px;
    }

    .auto-width {
      width: 100%;
      white-space: nowrap;

      //display: block !important;
      .title {
        width: 100%;
        text-overflow: ellipsis;
        overflow: clip;
        display: inline-block !important;
        vertical-align: center;
      }

      .title::first-letter {
        text-transform: uppercase;
      }
    }
  }

  .legend-header-title {
    font-family: MontserratMedium, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #363981;
  }

  .select-all-works {
    float: right;

    #custom-switch-all-providers {
      width: 25px;
      height: 15px;
    }

    #custom-switch-all-providers:checked {
      width: 25px;
      height: 15px;
      margin-top: 5px;
      background-color: #363981;
      border-color: #363981;
    }

    label {
      float: left;
      margin-top: 3px;
      font-family: MontserratMedium, sans-serif;
      font-weight: 800;
      font-size: 13px;
      color: #363981;
    }
  }

  .spw-header {
    background: linear-gradient(90deg, #03051b, #363981);
    border-radius: 15px;

    .spw-header-bg {
      background: url('../../assets/img/Ellipse9.svg');
      background-size: cover;
      width: 100%;
      display: inline-flex;
    }

    p {
      font-family: MontserratMedium, sans-serif;
      font-weight: 500;
      font-size: 24px;
      color: white;
      position: relative;
      top: 8px;
    }

    .description {
      font-family: MontserratMedium, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: white;
    }
  }

  .spw-header-bottom {
    border-radius: 15px;
  }

  /** Switch work search css **/
  .work-search-switch {
    background: #363981;
    border-radius: 28px;

    span {
      font-family: MontserratMedium, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: white;
      cursor: pointer;
    }

    .active {
      background: #fff;
      border-radius: 29px;
      color: black !important;
    }
  }

  .work-search-input {
    border-radius: 50px !important;
    border: 2px solid #363981 !important;
    height: 48px !important;
  }

  .work-search-input:focus {
    color: #212529;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none !important;
  }

  .work-search-button {
    border-radius: 50px !important;
    border-color: #363981 !important;
    background-color: #363981 !important;
    height: 40px;
    width: 80px;
    left: -82px;
    top: 4px;
    z-index: 1000 !important;
  }

  .work-search-list {
    background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(46.22deg, #363981 25.26%, #363981 75.83%) border-box !important;
    border: 3px solid transparent;
    border-radius: 20px;
    align-items: center;
    margin-top: -10px;
    z-index: 75;
    position: absolute;
    min-height: 50px;
    width: 57.4%;
    margin-left: 10px;
    display: flex;
    padding: 10px 10px 10px 0;

    ul {
      white-space: nowrap;
      max-height: 250px;
      list-style: none;
      width: 100%;
      padding-left: 10px;

      li {
        cursor: pointer;
        text-transform: capitalize !important;
        font-family: MontserratSemiBold, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 19px;
        padding: 5px 15px;

        .badge {
          width: 38px;
          height: 20px;
          font-size: 10px;
        }

        .spn-tiers {
          color: #ac3383;
          font-family: MontserratLight, sans-serif;
        }

        .fa-check-square {
          color: blue;
        }
      }

      li::first-letter {
        text-transform: uppercase;
      }

      li:hover {
        background-color: #e5eaf5;
        border-radius: 40px;
      }
    }

    .search-button {
      button {
        border-radius: 8px !important;
        border-color: transparent;
        padding: 4px 25px;
        line-height: normal !important;
        margin: 0 2px;
      }

      button:disabled,
      button[disabled],
      button[disabled]:hover {
        background-color: gray !important;
        color: #fff;
      }

      .apply {
        background-color: #363981e0;
        color: #fff;
      }

      .apply:hover {
        background-color: #363981;
      }

      .cancel {
        background-color: transparent;
        color: black;
        border: none;
      }

      .cancel:hover {
        background-color: rgba(0, 0, 0, 0.15);
      }
    }
  }
}