@import "../../../../variables";

.filters-bar {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.135216);
  display: flex;
  align-items: center;

  .filters-rows {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .react-datepicker-wrapper {
      padding: 6px 25px 6px 8px;
    }
    .collapse.show {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .filter-col {
      display: flex;
      margin: 10px 5px;
    }

    .filters-label {
      font: 16px MontserratSemiBold, sans-serif;
      line-height: 20px;
      margin-right: 5px;
    }

    .filter-actions {
      display: flex;
      margin-left: 5px;
    }

    .filter-input {
      background: $color-white;
      border: 1px solid $color-grey;
      border-radius: 100px;
      padding: 4px 25px 4px 8px;
      font: 14px MontserratRegular, sans-serif;
      line-height: 21px;
      color: $color-dark;
      width: 215px;
      -moz-appearance:none; /* Firefox */
      -webkit-appearance:none; /* Safari and Chrome */
      appearance:none;

      &.icon-works-list { background: $color-white url(/assets/img/icon-works-list-small.svg) 96% center no-repeat; }
      &.icon-number { background: $color-white url(/assets/img/icon-number-small.svg) 96% center no-repeat; }
      &.icon-calendar {
        background: $color-white url(/assets/img/icon-calendar-small.svg) 96% center no-repeat;
        width: 120px;
      }
      &.icon-status {
        width: 137px;
        padding: 4px 25px 4px 8px;
        background: $color-white url(/assets/img/icon-drop-down-small.svg) 96% center no-repeat;
      }

      &:focus-visible {
        outline: none;
      }

      option[value]:not([value='']) {
        color: $color-dark;
      }
    }

    .btn-search {
      width: 99px;
      height: 29px;
      border-radius: 4px;
      border: solid 1px $color-dark;
      background: transparent;
      text-align: center;
      font: 14px MontserratMedium, sans-serif;
    }

    .divider {
      margin: 2px 10px 0px 20px;
      height: 25px;
      border-left: 2px solid $color-grey;
    }

    .btn-reset {
      background: url(/assets/img/icon-reinitialiser.svg) no-repeat;
      border: none;
      width: 25px;
      height: 30px;
    }
  }
}

@media(max-width: 1200px) {
  .filters-label {
    padding-top: 10px;
  }
  .filters-bar .filters-rows .filter-input {
    width: 167px !important;
  }
  .filter-col{
    margin: 10px 2px !important;
    .filter-input {
      font-size: 12px !important;
    }
  }
}

@media (max-width: 576px) {

  .filters-bar .filters-rows .filter-input {
    width: 100% !important;
  }
  .filters-bar {
    padding: 15px 0;

    .filters-rows {
      display: initial;

      .btn-collapse {
        width: 24px;
        height: 24px;
        border: none;
        outline: none;
        background: transparent url(/assets/img/arrow-down-dark.svg) center no-repeat;

        &.out {
          transform: rotate(180deg);
        }
      }

      .collapse {
        margin-top: 15px;
        flex-direction: column;
        align-items: flex-start;
      }

      .filter-col {
        margin: 5px 0;
        width: 100%;

        .filter-input,
        .react-datepicker-wrapper {
          width: 100%;

          input {
            width: auto;
          }
        }

        .react-datepicker__triangle {
          display: none;
        }
      }

      .filter-actions {
        justify-content: flex-end;
        padding: 5px;
        width: 100%;
      }
    }
  }

}