body {
  background-color: #f7f7f2 !important;
}

a {
  text-decoration: none !important;
}

.loading-data {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 150px 0;
}

.centered-spinner {
  position: fixed;
  top: 50%;
  left: 48%;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px!important;
  }
}
