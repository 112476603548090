@import "../../variables.scss";

.work-container {
  font-family: Montserrat, sans-serif;
  padding: 0 !important;
  .artwork-title {
    background: $color-bg-card;
  }
  .value-bold {
    font: 16px MontserratSemiBold, sans-serif;
  }
  .align-right {
    text-align: right;
  }
  .display-label {
    text-align: right;
    font: 14px MontserratRegular, sans-serif;
    line-height: 17px;
    letter-spacing: -0.2px;
    color: #606060;
  }
}

.artwork-body {
  padding: 5px 0 5px 45px;
  margin-bottom: 15px;

  .interested-party-title {
    font: 14px MontserratSemiBold, sans-serif;
    flex-wrap: wrap;
    flex-grow: 0;
    line-height: 17px;
    display: inherit;
    align-items: center;
    justify-content: flex-end;
    letter-spacing: -0.2px;
    color: $color-grey;
    order: 0;
    padding: 0px 5px;
    margin: 0px;
  }
  .separator {
    padding: 0;
    margin: 0;
    height: 1px;
    width: 100%;

    border: 1px dashed $color-grey;
    transform: matrix(1, 0, 0, -1, 0, 0);
    order: 1;
    display: inline-block;
    vertical-align: middle;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
}

.arrow {
  display: block;
  padding: 5px 11px;
  cursor: pointer;
}

@media (max-width: $layout-breakpoint-small) {
  .artwork-title {
    padding-left: 1px;

    .work-numbers {
      font-size: 14px;
      letter-spacing: 2px;
      align-content: center;
    }

    .icon {
      flex-direction: column;
      justify-content: center;
      align-content: center;

      img.-oeuvre-note {
        // font-size: 12px;
      }
    }
  }

  .artwork-body {
    font-size: 14px;
    padding: 5px 0 5px 20px;

    .interested-party-title {
      // font: 12px MontserratSemiBold, sans-serif;
      padding: 0;
    }
  }

  .arrow {
    padding: 5px 2px;
  }
}
