@import "../../fonts.sass";
@import "../../variables.scss";
.box-section {
  background: white !important;
  position: relative;
  border-radius: 15px;
  height: inherit;
  box-shadow: 0 4px 9px rgba(66, 89, 147, 0.25);
  overflow: hidden;
  --bs-gutter-x: 0.7rem;
  --bs-gutter-y: 0;

  .custom-spinner {
    text-align: center;
    margin-top: 25px;
  }

  .box-section-header {
    background: $webso-box-section-background;
    height: 47px;
    border-radius: 15px 15px 0 0;
    color: white;
    padding-block: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .switch {
      align-self: center;
      font-size: small;
    }

    .title {
      font-family: MontserratSemiBold, sans-serif;
      padding-right: 10px;
      color: inherit;
      font-size: large;
    }

    .sub-title {
      color: rgba(255, 255, 255, 0.616);
      font-family: MontserratRegularItalic, sans-serif;
      font-size: x-small;
      font-style: italic;
    }
  }

  .box-section-footer {
    font-weight: 700;
    border-radius: 0 0 15px 15px;
    width: 100%;
    background: #eff0f5;
    height: 47px;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
  }

  .sort-line {
    margin-inline: 20px;
    border-top: 1px solid #36398154;
    width: inherit;
    height: 0;
  }

  .sort-alpha-icon-off {
    border: none;
    height: 30px;
    width: 60px;
    cursor: pointer;
    background: url('./../../assets/img/box/box-footer-sort-alpha-off.svg')
      no-repeat;
  }

  .sort-alpha-icon-on {
    border: none;
    height: 30px;
    width: 60px;
    cursor: pointer;
    background: url('./../../assets/img/box/box-footer-sort-alpha-on.svg')
      no-repeat;
  }

  .sort-money-icon-off {
    border: none;
    height: 30px;
    width: 72px;
    cursor: pointer;
    background: url('./../../assets/img/box/box-footer-sort-money-off.svg')
      no-repeat;
  }

  .sort-money-icon-on {
    border: none;
    height: 30px;
    width: 72px;
    cursor: pointer;
    background: url('./../../assets/img/box/box-footer-sort-money-on.svg')
      no-repeat;
  }

  .form-check {
    .form-check-input {
      width: 30px;
      height: 15px;
    }

    .form-check-label {
      font-size: 0.8rem;
      margin-right: 0.5rem;
    }

    .form-check-input:checked {
      background-color: #363981;
      border-color: #363981;
    }
  }
}
