@import '../../variables.scss';
@import '../../fonts.sass';

.webso-setlist-page {
    .primary-title{
      font: 16px MontserratRegular, sans-serif;
      font-weight: 600;
      line-height: 20px;
    }

    .text-label {
      font-weight: 600;
      font-family: MontserratSemiBold, sans-serif;
      color: $color-dark-title;
      size: 16px;
    }
    .table-loading {
      width: 60px;
      margin: auto;
    }

    .loading-container {
      width: 100%;
      height: 600px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .draft-label {
      right: 0;
      margin-right: 20px;
      padding: 5px;
      font-family: MontserratRegular, sans-serif;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #3d4366;
    }
    .data-label {
      font-family: MontserratRegular, sans-serif;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262a40;
    }

    .data-number {
      font-family: MontserratRegular, sans-serif;
      margin-right: 20px;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #bc204b;
    }

    .header-padding{
      padding: 0px 50px 0 30px;
    }

    .nb-card{
      @extend .primary-title;
      color: $blue-color;
      margin-right: 20px;
      font-weight: 600;
      font-family: MontserratSemiBold, sans-serif;
      size: 16px;
    }

    .Separate-vertical {
      width: 2px;
      height: 29px;
      border-radius: 2px;
      background-color: #606060;
    }

    .badge-list-oeuvres {
      height: 25px;
      margin: 1px 0 0 2px;
      padding: 0 10px 1px 20px;
      border-radius: 4px;
      background-color: $color-dark;
    }

    .badge-list-oeuvres:hover{
      background-color: $color-hub;
    }


    .badge{
      width: 70px;
      height: 30px;
      flex-grow: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 20px;
      padding: 6px 12px;
      border-radius: 100px;
      border: solid 1px rgba(44, 46, 54, 0.35);
      background-color: $color-primary;
      cursor:pointer;
    }

    li {
      list-style-type: none;
    }
}