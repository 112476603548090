@import "../../variables";

.empty-data {
  padding: 100px 0;
  text-align: center;
  font: 18px MontserratSemiBold, sans-serif;
  color: $color-dark;
}
.toast-ctr{
  border-radius: 4px!important;
  box-shadow: 0 16px 48px 0 rgba(0, 0, 0, 0.18)!important;
  border: solid 1px #725fbf!important;
  background-color: #725fbf !important;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #fff;}
.data-header {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  .count {
    display: flex;
    align-items: center;
    .number {
      font: 16px MontserratSemiBold, sans-serif;
      color: $color-primary;
      margin-right: 35px;
    }
    .label {
      font: 16px MontserratSemiBold, sans-serif;
    }
  }
  .actions {
    display: flex;
    align-items: center;

    .sort-label {
      margin: 0 15px;
      font: 14px MontserratMedium, sans-serif;
      line-height: 21px;
    }
  }
}

.toast-container {
  margin-top: 63px;
  font-size: 18px;
  font-weight: bold;
  .toast {
    background-color: $color_beige_light;
    opacity: 1 !important;
    border: 1px $color-light-grey solid;
    border-radius: 5px;
    max-width: 600px;
    width: 100%;
    .success {
      background: url("../../assets/img/icon-ok.svg") no-repeat left 12px center !important;
      padding-left: 48px;
    }
  }
}
.livestream{
  cursor: pointer;
  font: 14px MontserratRegular, sans-serif;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-right: 10px;
  a{
    font: 16px MontserratBold, sans-serif;
    color: $color-primary;
    &:hover{
      color: $color-primary;
    }
  }
}
@media (max-width: 576px){
  .data-header {
    padding: 5px 0;

    .count {
      .number {
        font: 14px MontserratSemiBold, sans-serif;
        margin-right: 10px;
      }
      .label {
        font: 14px MontserratSemiBold, sans-serif;
      }
    }

    .actions {
      .sort-label {
        display: none;
      }

      .form-check.form-switch .form-check-label {
        font: 12px MontserratRegular, sans-serif;
      }
    }
  }
}