@import "../../variables.scss";

.rgpd-bottom {
  display: flex;
  flex-direction: column;
  min-width: 0;

  .info-bottom {
    font: 14px MontserratRegular, sans-serif;
    line-height: 21px;
    margin: 0 12.5px 15px 12.5px;
    padding: 15px 20px 15px 50px;
    border-radius: 4px;
    border: solid 1px $color-grey-light;
    color: $color-grey;
    background: url("../../assets/img/icon-info-black-small.svg") $color-white no-repeat 12px 14px;
  }

  .rgpd {
    background: none;
    color: $color-dark !important;
    margin: 10px 0;
    padding: 0;
    a {
      text-decoration: underline;
    }
  }
}

