@import "../../variables.scss";

.breadcrumb {
  height: 70px;
  margin:  0 !important;
  padding: 0 150px 0 145px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.14);
  // background-image: linear-gradient(0.25turn, #a1c1f7, #2f75ee, #433285);
  background: linear-gradient(270deg, #433285 0%, #2F75EE 84.37%, #A1C1F7 100%);
  vertical-align: middle;
  .flag-tag {
    font-size: x-small;
    font-weight: normal;
    font-family: 'MontserratRegular';
    font-style: italic;
  }
  .beta-badge {
    margin-left: 0 !important;
    color: #fff !important;
  }
  .title {
    font: 24px MontserratBold, sans-serif !important;
    line-height: 36px !important;
    color: $color-white !important;;
  }

  .sub-level {
    font: 24px MontserratBold, sans-serif !important;
    line-height: 21px !important;
    color: $color-white !important;
    text-decoration: none !important;

    &:first-child {
      color: $color-white;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .separator {
    display: inline-block;
    width: 20px;
    height: 25px !important;
    background: url(/assets/img/arrow-right-small.svg) center no-repeat !important;
    background-size: 24px 14px;
    position: relative;
    top: 5px;
    margin: 0 10px;
  }

  .home{
    width: 30px;
    height: 30px;
    background: url(/assets/img/icon-home.svg) no-repeat center;
    cursor: pointer;
    margin-left: -5px;
  }
  .link {
    cursor: pointer;
    color: #433285;
  }
}
