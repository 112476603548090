@import '../../variables.scss';

.toggle-container {
  display: flex;
  background: $color-white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.13);
  border-radius: 100px;
  color: $color-grey;

  .toggle-item {
    font: 14px MontserratMedium, sans-serif;
    line-height: 21px;
    padding: 4px 0px;
    cursor: pointer;
    text-align: center;
    flex: 1;
    min-width: 140px;

    &.active {
      color: $color-white;
      background-color: $blue-color;
      border-radius: 100px;
    }

    span {
      background-position: left center;
      background-repeat: no-repeat;
      padding-left: 25px;

      &.icon-toggle-setlist {
        background-image: url(/assets/img/icon-toggle-setlist.svg);
        &.active {
          background-image: url(/assets/img/icon-toggle-setlist-active.svg);
        }
      }
      &.icon-toggle-draft {
        background-image: url(/assets/img/icon-toggle-draft.svg);
        &.active {
          background-image: url(/assets/img/icon-toggle-draft-active.svg);
        }
      }
      &.icon-toggle-event-past {
        background-image: url(/assets/img/icon-toggle-event-past.svg);
        &.active {
          background-image: url(/assets/img/icon-toggle-event-past-active.svg);
        }
      }
      &.icon-toggle-event-upcoming {
        background-image: url(/assets/img/icon-toggle-event-future.svg);
        &.active {
          background-image: url(/assets/img/icon-toggle-event-future-active.svg);
        }
      }
    }
  }
}