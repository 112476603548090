.usage-data-body-container {
  .form-check-inline {
    display: inline-block !important;
  }
  .form-check {
    display: block;
    min-height: 1.5rem;
    margin-bottom: 0.125rem;
  }
  .form-switch {
    padding-left: 0.5em;
  }
}
.usage-data-details-container {
  background: #e5eaf5 !important;
  padding-left: 64px !important;
  width: 100% !important;
  height: 97%;
}

.usage-data-details-block {
  position: relative;
  padding-block: 6vh;

  p {
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: black;
    position: relative;
    top: 8px;
  }

  .usage-data-details-block-header {
    background: linear-gradient(90deg, #03051b, #363981);
    border-radius: 15px 15px 0 0;

    p {
      color: white;
    }
  }
}

.usage-data-details-works-container {
  padding-left: 24px !important;
}

.usage-data-details-block-lateral {
  height: 300px;
}

.usage-data-details-works-container-body {
  border: 1px solid rgba(211, 211, 211, 0.636);
  height: 350px !important;
  border-radius: 0 0 15px 15px;

  ul {
    white-space: nowrap;
    list-style: none;
    margin: 0;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: #363981 #cad7f4;
    scrollbar-width: thin;

    li {
      padding: 3px 0;
      cursor: pointer;
      margin-left: -30px;
      font-size: 15px;
      font-family: Montserrat, sans-serif;
      font-weight: 200 !important;
    }
  }
}

.usage-data-resource-id {
  .work-title {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    text-transform: capitalize;
  }

  .resource-font {
    font-family: Montserrat, sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 15px !important;
  }

  .ud-search {
    width: 80% !important;

    span {
      background-color: #363981 !important;
      border-color: #363981 !important;
      border-radius: 20px 0 0 20px !important;
    }

    input {
      border-color: #363981 !important;
      width: 70% !important;
      border-right: 0 !important;
    }

    input:focus {
      box-shadow: none;
    }

    button {
      border-color: #363981 !important;
      border-left: 0 !important;
      border-radius: 0 20px 20px 0 !important;
    }

    button:hover {
      background-color: #363981 !important;
    }
  }
}
