.usage-data-line-chart {
  .chart-bg {
    background: linear-gradient(0deg, #e5eaf5 81.78%, #fff 99.21%);
    position: relative;
  }

  .custom-justify {
    justify-content: center;
  }

  .courbe-style {
    background: linear-gradient(rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  }
}
