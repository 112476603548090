@import "../../../../variables";

.btn-sort {
  font: 14px MontserratMedium, sans-serif;
  border: none;
  padding: 5px 26px 5px 8px;
  color: $color-grey;
  background: url(/assets/img/arrow-down-dark.svg) right 60% no-repeat;
  // arrow-down-dark.svg
  @media(max-width: $layout-breakpoint-small) {
    width: 24px;
    height: 24px;
    padding: 0;
    font-size: 0; // hide label in mobile
    background: url(/assets/img/icon-filter-list.svg) no-repeat;
  }
}

#sort-popover {
  width: fit-content;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.13);
  border: solid 1px #bdc2cc;

  .sort-options {
    padding: 4px 0;
    .option {
      font: 14px MontserratRegular, sans-serif;
      padding: 0 15px;
      height: 32px;
      display: flex;
      align-items: center;
      color: $color-grey;

      &:hover {
        background-color: $color-bg;
        cursor: pointer;
      }
    }
  }

  .popover-arrow {
    display: none !important;
  }
}
