@import '../../../../variables.scss';

.primary-title {
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: MontserratRegular, sans-serif;
}
.work-card{
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.13);
  margin-bottom: 30px;
  .header-card{
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    min-height: 50px;
    align-items: stretch;
    background:  url("../../../../assets/img/icon-work-light.svg") no-repeat left 15px center $color-bg-card;
    .header-title {
      font: 18px MontserratSemiBold, sans-serif;
      line-height: 27px;
      margin: 8px 50px;
      color: $color-dark!important;
    }
  }
  .content-card{
    color: $color-dark;
    ul{
      list-style: none;
      padding-left: 0;
    }
    label{
      @extend .primary-title;
      font-family: MontserratSemiBold!important;
      font-size: 16px;
      line-height: 1.5;
      text-align: left;
      padding: 6px 9px 6px 5px;
      @media (max-width: $layout-breakpoint-medium) {
        font-size: 14px;
      }
    }
    span{
      @extend .primary-title;
      font-family: MontserratMedium, sans-serif;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      display: inline-block;
      vertical-align: middle;
      padding-top: 6px;
      color: $color-dark;
    }
    .add-work-to-list{
      font-family: MontserratMedium, sans-serif;
      font-weight: 500;
      text-align: center;
      font-size: 16px;
      width: 160px;
      height: 36px;
      padding: 6px 12px;
      margin-top: 6px;
      border-radius: 4px;
      border: solid 1px $color-text;
      background-color: $color-white;
      cursor: pointer;
      &:hover{
        color: $color-white;
        background-color: $color-text;
      }
    }
  }

  .icon-showMore{
    width: 35px;
    height: 35px;
    border-radius: 100%;
    align-items: stretch;
    margin: 7px;
    background:  url("/assets/img/icon-arrow-up-small-dark.svg") no-repeat left 5px center;
    cursor: pointer;
    &:hover{
      background-color: $color-bg;
    }
    &.rotateIcon{
      transform: rotate(180deg);
    }
  }

}