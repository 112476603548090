/** =================== MONTSERRAT =================== **/

/** Montserrat Thin **/
@font-face {
  font-family: "MontserratThin";
  font-weight: 100;
  font-style: normal;
  src: url("assets/fonts/Montserrat-Thin.woff2") format("woff2"), url("assets/fonts/Montserrat-Thin.woff") format("woff"); }

/** Montserrat Thin-Italic **/
@font-face {
  font-family: "MontserratThinItalic";
  font-weight: 100;
  font-style: italic;
  src: url("assets/fonts/Montserrat-ThinItalic.woff2") format("woff2"), url("assets/fonts/Montserrat-ThinItalic.woff") format("woff"); }

/** Montserrat ExtraLight **/
@font-face {
  font-family: "MontserratExtraLight";
  font-weight: 200;
  font-style: normal;
  src: url("assets/fonts/Montserrat-ExtraLight.woff2") format("woff2"), url("assets/fonts/Montserrat-ExtraLight.woff") format("woff"); }

/** Montserrat ExtraLight-Italic **/
@font-face {
  font-family: "MontserratExtraLightItalic";
  font-weight: 200;
  font-style: italic;
  src: url("assets/fonts/Montserrat-ExtraLightItalic.woff2") format("woff2"), url("assets/fonts/Montserrat-ExtraLightItalic.woff") format("woff"); }

/** Montserrat Light **/
@font-face {
  font-family: "MontserratLight";
  font-weight: 300;
  font-style: normal;
  src: url("assets/fonts/Montserrat-Light.woff2") format("woff2"), url("assets/fonts/Montserrat-Light.woff") format("woff"); }

/** Montserrat Light-Italic **/
@font-face {
  font-family: "MontserratLightItalic";
  font-weight: 300;
  font-style: italic;
  src: url("assets/fonts/Montserrat-LightItalic.woff2") format("woff2"), url("assets/fonts/Montserrat-LightItalic.woff") format("woff"); }

/** Montserrat Regular **/
@font-face {
  font-family: "MontserratRegular";
  font-weight: 400;
  font-style: normal;
  src: url("assets/fonts/Montserrat-Regular.woff2") format("woff2"), url("assets/fonts/Montserrat-Regular.woff") format("woff"); }

/** Montserrat Regular-Italic **/
@font-face {
  font-family: "MontserratRegularItalic";
  font-weight: 400;
  font-style: italic;
  src: url("assets/fonts/Montserrat-Italic.woff2") format("woff2"), url("assets/fonts/Montserrat-Italic.woff") format("woff"); }

/** Montserrat Medium **/
@font-face {
  font-family: "MontserratMedium";
  font-weight: 500;
  font-style: normal;
  src: url("assets/fonts/Montserrat-Medium.woff2") format("woff2"), url("assets/fonts/Montserrat-Medium.woff") format("woff"); }

/** Montserrat Medium-Italic **/
@font-face {
  font-family: "MontserratMediumItalic";
  font-weight: 500;
  font-style: italic;
  src: url("assets/fonts/Montserrat-MediumItalic.woff2") format("woff2"), url("assets/fonts/Montserrat-MediumItalic.woff") format("woff"); }

/** Montserrat SemiBold **/
@font-face {
  font-family: "MontserratSemiBold";
  font-weight: 600;
  font-style: normal;
  src: url("assets/fonts/Montserrat-SemiBold.woff2") format("woff2"), url("assets/fonts/Montserrat-SemiBold.woff") format("woff"); }

/** Montserrat SemiBold-Italic **/
@font-face {
  font-family: "MontserratSemiBoldItalic";
  font-weight: 600;
  font-style: italic;
  src: url("assets/fonts/Montserrat-SemiBoldItalic.woff2") format("woff2"), url("assets/fonts/Montserrat-SemiBoldItalic.woff") format("woff"); }

/** Montserrat Bold **/
@font-face {
  font-family: "MontserratBold";
  font-weight: 700;
  font-style: normal;
  src: url("assets/fonts/Montserrat-Bold.woff2") format("woff2"), url("assets/fonts/Montserrat-Bold.woff") format("woff"); }

/** Montserrat Bold-Italic **/
@font-face {
  font-family: "MontserratBoldItalic";
  font-weight: 700;
  font-style: italic;
  src: url("assets/fonts/Montserrat-BoldItalic.woff2") format("woff2"), url("assets/fonts/Montserrat-BoldItalic.woff") format("woff"); }

/** Montserrat ExtraBold **/
@font-face {
  font-family: "MontserratExtraBold";
  font-weight: 800;
  font-style: normal;
  src: url("assets/fonts/Montserrat-ExtraBold.woff2") format("woff2"), url("assets/fonts/Montserrat-ExtraBold.woff") format("woff"); }

/** Montserrat ExtraBold-Italic **/
@font-face {
  font-family: "MontserratExtraBoldItalic";
  font-weight: 800;
  font-style: italic;
  src: url("assets/fonts/Montserrat-ExtraBoldItalic.woff2") format("woff2"), url("assets/fonts/Montserrat-ExtraBoldItalic.woff") format("woff"); }

/** Montserrat Black **/
@font-face {
  font-family: "MontserratBlack";
  font-weight: 900;
  font-style: normal;
  src: url("assets/fonts/Montserrat-Black.woff2") format("woff2"), url("assets/fonts/Montserrat-Black.woff") format("woff"); }

/** Montserrat Black-Italic **/
@font-face {
  font-family: "MontserratBlackItalic";
  font-weight: 900;
  font-style: italic;
  src: url("assets/fonts/Montserrat-BlackItalic.woff2") format("woff2"), url("assets/fonts/Montserrat-BlackItalic.woff") format("woff"); }

/** Poppins Regular **/
@font-face {
  font-family: "PoppinsRegular";
  font-weight: 400;
  src: url("assets/fonts/Poppins-Regular.ttf"); }

/** Poppins Bold **/
@font-face {
  font-family: "PoppinsBold";
  font-weight: 700;
  src: url("assets/fonts/Poppins-Bold.ttf"); }
