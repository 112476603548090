.usage-data-container {
  padding-left: 63px !important;
  padding-right: 0 !important;
  .box-section {
    .input-group{
      flex-wrap: nowrap;
    }
  }
  .usage-data {
    .box-section {
      .input-group {
        flex-wrap: nowrap;
      }
    }

    .filter-date {
      .react-datepicker__input-container {
        display: flex;
        justify-content: center;
      }
    }

    .react-datepicker-wrapper {
      display: inline-block;
      padding: 0;
      border: 0;
      background: none;
      width: 100%;
    }

    .mantine-Input-wrapper {
      .mantine-Input-input, .mantine-Select-input {
        font-family: MontserratMedium, sans-serif;
      }
    }
  }

  .scroll-style {
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: #12164a #cad7f4;
    scrollbar-width: thin;
  }

  *::-webkit-scrollbar {
    width: 5px;
  }

  *::-webkit-scrollbar-track {
    background: #cad7f4;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #333880;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(51, 56, 128, 0.68);
  }

  .ps-2 {
    padding-left: 1rem !important;
  }

  .pe-0 {
    padding-right: 0 !important;
  }

  .ps-3 {
    padding-left: 1rem !important;
  }

  .pe-1 {
    padding-right: 0.25rem !important;
  }

  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .m-2 {
    margin: 0.5rem !important;
  }

  .select-all-works {
    .form-check.form-switch {
      padding-left: 2.5em !important;
      display: block;
      min-height: 1.5rem;
      margin-bottom: 0.125rem;

      .form-check-label {
        line-height: 21px;
      }

      #custom-switch-all-providers:checked {
        width: 25px;
        height: 15px;
        margin-top: 5px;
        background-color: #363981;
        border-color: #363981;
      }
    }

  }

  .usage-data-legends {
    .artists-list li {
      display: list-item;
      list-style: disc;
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
    }

    .form-check.form-switch {
      padding-left: 3.5em !important;
      display: block;
      min-height: 1.5rem;
      margin-bottom: 0.125rem;

      .form-check-label {
        font: 14px MontserratRegular, sans-serif;
        line-height: 22px;
        float: left;
        color: #606060;
      }
    }
  }

  .usage-data-map-container {
    position: relative;
    max-width: 500px !important;
    padding-block: 6vh;
    padding-left: 24px !important;

    p {
      font-family: MontserratMedium, sans-serif;
      font-weight: 500;
      font-size: 24px;
      color: black;
      position: relative;
      top: 8px;
    }

    .usage-data-map-container-header {
      background: linear-gradient(90deg, #03051b, #363981);
      border-radius: 15px 15px 0 0;

      p {
        color: white;
      }
    }

    .usage-data-map-col {
      padding: unset !important;
      height: 500px;
    }

    .usage-data-accordion-col {
      padding: unset !important;
      overflow-y: auto;
      height: 500px;
    }
  }

  .usage-data-map-footer-row {
    background: white;
    border-top: 1px solid grey;
    font-family: MontserratMedium, sans-serif;
  }

  .stream-number-label {
    padding: 10px 0 0 20px;
  }

  .map-legend-container {
    display: flex;
    padding: 20px 20px 40px;
  }

  .legend-segment-container {
    width: 9%;
  }

  .colored-legend-element {
    height: 15px;
  }

  .legend-max-value-element {
    display: flex;
    justify-content: center;
    font-size: 10px;
  }

  .country-hover-info {
    padding-inline: 10px;
    font-family: MontserratMedium, sans-serif;
    font-size: 16px;
    height: fit-content;
    background-color: white;
    color: black;
    border: 2px solid purple;
    border-radius: 5px;
  }

  .country-hover-info-mobile {
    padding-inline: 10px;
    width: fit-content;
    font-family: MontserratMedium, sans-serif;
    font-size: 16px;
    height: fit-content;
    background-color: white;
    color: black;
    border: 2px solid purple;
    border-radius: 5px;
  }

  .usage-data-map-body {
    min-height: 628px;
    border: 1px solid rgba(211, 211, 211, 0.636);
  }

  .usage-data-card {
    border-radius: 15px 15px 0 0 !important;

    .usage-data-map-container-header {
      border-radius: 15px 15px 0 0 !important;
      background: linear-gradient(90deg, #03051b, #363981) !important;
      color: white !important;
      font-family: MontserratMedium, sans-serif;
      font-weight: 500;
      font-size: 24px;
    }

    .usage-data-map-col {
      padding: unset !important;
      height: 350px;
    }

    .usage-data-accordion-col {
      padding: unset !important;
      overflow-y: auto;
      height: 500px;
    }

    .usage-data-map-footer-row {
      background: white;
      border-top: 1px solid grey;
      font-family: MontserratMedium, sans-serif;
    }

    .stream-number-label {
      padding: 10px 0 0 20px;
    }

    .map-legend-container {
      display: flex;
      padding: 20px 20px 40px;
    }

    .legend-segment-container {
      width: 10%;
    }

    .colored-legend-element {
      height: 15px;
    }

    .legend-max-value-element {
      display: flex;
      justify-content: center;
      font-size: 10px;
    }

    .clear-all {
      text-align: center;
      width: 77px;
      cursor: pointer;
      border-radius: 20px !important;
      line-height: normal !important;
      margin: 0 2px;
      background-color: transparent;
      color: black;
      border: none;
      height: 23px;
      cursor: pointer;
    }

    .clear-all:hover {
      background-color: rgba(204, 204, 204, 0.15);
    }
  }

  /* -------------- progress bar -------------------------- */
  .container-progressbar {
    position: relative;
    max-width: 500px !important;
    padding-block: 6vh;
    padding-left: 24px !important;

    .container-progressbar-header {
      background: linear-gradient(90deg, #03051b, #363981);
      border-radius: 15px 15px 0 0;

      p {
        color: white;
      }
    }

    .container-progressbar-body {
      border-radius: 0 0 15px 15px;
      border: 1px solid rgba(211, 211, 211, 0.636);
    }

    p {
      font-family: MontserratMedium, sans-serif;
      font-weight: 500;
      font-size: 24px;
      color: black;
      position: relative;
      top: 8px;
    }

    .loading-use-type {
      width: 60px;
      margin: auto;
    }
  }

  .bar-container {
    padding-bottom: 15px;
    padding-top: 15px;

    .mobile-bar-label {
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 50% !important;
    }

    .bar-label {
      width: 152px;
      height: 24px;
      font-size: 18px;
      font-family: MontserratMedium, sans-serif;
      line-height: 1.5;
      color: #2c2e36;
      font-weight: bold;
    }

    .bar-amount {
      width: 150px;
      height: 19px;
      line-height: 1.5;
      text-align: right;
      color: #606060;
      float: right;
      font-size: 16px;
      font-family: MontserratMedium, sans-serif;
    }

    .bar-percent {
      width: 162px;
      height: 15px;
      font-size: 16px;
      font-family: MontserratMedium, sans-serif;
      line-height: 1.5;
      float: right;
      text-align: right;
      color: #9696a6;
    }

    .progress {
      height: 4px;
      background-color: #e9e9e9;
    }

    &:nth-child(1) .progress-bar {
      background-color: #bc204b;
    }

    &:nth-child(2) .progress-bar {
      background-color: #363981;
    }

    &:nth-child(3) .progress-bar {
      background-color: #e35205;
    }

    &:nth-child(4) .progress-bar {
      background-color: #f17080;
    }

    &:nth-child(5) .progress-bar {
      background-color: #b6a19b;
    }

    &:nth-child(6) .progress-bar {
      background-color: #b6a19b;
    }

    &:hover {
      cursor: default;
    }
  }
   .clear-all {
      text-align: center;
      width: 77px;
      cursor: pointer;
      border-radius: 20px !important;
      line-height: normal !important;
      margin: 0 2px;
      background-color: transparent;
      color: black;
      border: none;
      height: 23px;
    }
}

