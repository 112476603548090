.advanced-search-page-container {
  background-color: rgba(0, 0, 0, 0.009);
  .react-datepicker-wrapper {
    all: unset;
    border: 1px solid #ced4da;
    border-radius: 100px;
    width: 81%;
    padding: 0px 35px 0px 0px;
    background: #e4e7eb url(/assets/img/icon_new_calendar.svg) 96% center no-repeat;
  }
  .date-month-range {
    border: unset;
    border-radius: 9999px 100px 100px 9999px !important;
    background-color: #ffffff !important;
  }
  .react-datepicker__input-container {
    all: unset;
    background: #fff;
    border-radius: 9999px 100px 100px 9999px !important;
  }

  .mantine-MultiSelect-input {
    height: 36px;
    border-radius: 80px;
    background: rgba(228, 231, 235, 1) url(/assets/img/arrow-down-full-select.svg) 96% center
      no-repeat !important;
    color: #939392;
    border: 1px solid #ced4da !important;
  }
  .mantine-MultiSelect-values {
    margin: unset;
    background: #fff;
    border-radius: 9999px 80px 80px 9999px !important;
    height: 35px;
  }
  .mantine-Select-rightSection {
    width: 45px;
    border-radius: 80px;
    background: rgba(228, 231, 235, 1) url(/assets/img/arrow-down-full-select.svg) 50% no-repeat !important;
    color: #939392;
  }
  .creatable-multiselect {
    width: 100%;
    .mantine-MultiSelect-input {
      background: unset !important;
    }
    .mantine-MultiSelect-rightSection {
      right: 5px !important;
      div {
        padding: unset !important;
      }
    }
    .mantine-MultiSelect-values {
      width: 95%;
    }
  }

  .mantine-Input-input {
    height: 37px;
    border-radius: 80px;
    border: 1px solid #ced4da !important;
    padding-left: 0;
  }
  .mantine-TextInput-input {
    padding-left: 10px;
  }

  .mantine-Select-wrapper {
    border-radius: 80px;
    background: rgba(228, 231, 235, 1) url(/assets/img/arrow-down-full-select.svg) 90% no-repeat !important;
    color: #939392;
    border: 1px solid #ced4da !important;
  }
  .mantine-Select-input {
    border-radius: 80px;
    border: unset !important;
    text-align: center;
  }
  .advanced-search-reset-btn {
    height: 33px;
    border: none;
    background-color: rgba(228, 231, 235, 1);
    border-radius: 2px;
    padding: 4px 10px 10px 10px;
  }
  .apply-button {
    border-radius: 6px;
    border: 1px solid rgba(54, 57, 129, 1);
    background-color: rgba(66, 69, 158, 1);
    color: #fff;

    div {
      border: none;
      background-color: unset;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .apply-button:disabled,
  .apply-button[disabled] {
    color: #666;
    border: 1px solid #999;
    background-color: #ccc;
  }
  .mantine-InputWrapper-root {
    width: 94% !important;
  }
  .mantine-MultiSelect-rightSection {
    right: 34px !important;
  }
  .mantine-MultiSelect-searchInput {
    margin-left: 10px;
  }
}
