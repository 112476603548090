.kpi-page-content {
  .license-kpi-territories-map-footer-row {
    background: white;
    font-family: Klamp205Medium, sans-serif;
    display: flex;
    padding: 5px 20px;
    justify-content: center;
  }

  .map-legend-container-license-kpi {
    padding-top: 5px;
    display: flex;
  }

  .colored-legend-element-license-kpi {
    height: 10px;
  }

  .legend-max-value-element-license-kpi {
    display: flex;
    justify-content: center;
    font-size: 10px;
  }

  .legend-segment-container-license-kpi {
    width: 10%;
  }

  #custom-switch-all-providers {
    height: 15px;
  }

  label {
    float: left;
    margin-top: 3px;
    font-weight: 600;
    font-size: 13px;
    color: #363981;
  }
}
