@import "../../variables.scss";

.privacy-cookies-policy {
  h2 {
    margin-top: 2em;
    margin-bottom: 1em;
    font-size: 18pt;
    color: $color-red;
  }

  h3 {
    font-size: 14pt;
  }


  h4 {
    font-size: 10pt;
  }

  tr,
  p,
  li,
  td {
    font-size: 9pt;
  }

  #personal_data_section,
  #cookies_section,  h4 {
    font-weight: bold;
  }

  .red-link {
    color: $color-red;
    text-decoration: underline !important;
  }

  .text-block {
    margin-top: 1em;
    margin-bottom: 2em;
  }

  td {
    padding-left: 1em;
    padding-right: 1em;

    > ul {
      list-style-position: inside;
      display: inline-block;
    }
  }

  ul {
    margin-top: 1em;
  }

  .ot-sdk-show-settings {
    color: $color-red !important;
    font-family: 'Segoe UI', sans-serif !important;
    font-size: 9pt !important;
    text-align: left !important;
    display: inline-block !important;
    left: 0 !important;
    border: none !important;
    padding: 0 !important;

    &:hover {
      background-color: unset !important;
    }
  }

    .fixed-bottom {
        padding: 0 15px;
        bottom: 10px !important;
        .back-top {
            border-radius: 10px;
            background-color: $color-border-pink;
            width: 50px;
            height: 50px;
            border: none;
            padding: 0;
            float: right;
        }
    }

    .arrow-up {
        background: url(/assets/img/icon-arrow-up-small-dark.svg) no-repeat center;
    }
}