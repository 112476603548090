@import "../../variables";

.filter-input-progs {
  float: right;
  font-size: 13px;
  vertical-align: middle;
  border-radius: 20px;
  border: 1px solid #606060;
  padding-right: 7px;
  background-color: $color-white;
  width: 100%;

  input {
    border: none;
    float: left;
    width: 100%;
    border-radius: 20px;
    padding: 5px 15px 5px 15px;
    height: 100%;
    color: $color-grey;

    &:focus-visible {
      outline: none;
    }
  }
  img{
    margin-top: 5px;
    width: 17px;
    height: 17px;
  }
}