.progress-bar-group-container {
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    font-family: Klamp205Medium, sans-serif;

    .first-row {
      display: flex;
      justify-content: space-between;

      .bar-label {
        font-size: 18px;
        color: #2c2e36;
        font-weight: bold;
      }

      .bar-amount {
        color: #606060;
        font-size: 16px;
      }
    }

    .progress {
      height: 4px;
      background-color: #e9e9e9;
      border-radius: 6px;

      .progress-bar {
        height: 4px;
        border-radius: 6px;
        transition: width .6s ease;
      }
    }

    .bar-percent {
      font-size: 16px;
      color: #9696a6;
      align-self: flex-end;
    }
  }