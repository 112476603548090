@import '../../variables.scss';

.interested-parties-body {
  margin: 0;
  padding: 0 0 0 20px;

  .interested-parties-icon {
    width: auto;
  }

  .name {
    margin-left: -2px
  }

  .interested-parties-name {
    font: 16px MontserratSemiBold, sans-serif;
    line-height: 20px;
    color: $color-dark;
    padding: 0 10px;
    vertical-align: sub;
  }

  .interested-parties-ipi {
    font: 14px MontserratMedium, sans-serif;
    line-height: 17px;
    color: $color-grey;
    padding: 0 15px;
    vertical-align: text-top;

    @media(max-width: 575px) {
      //font: 12px MontserratMedium, sans-serif;
    }
  }

  .interested-parties-editor {
    font: 14px MontserratMedium, sans-serif;
    line-height: 17px;
    color: $color-grey;
    padding: 0 15px;
    vertical-align: text-top;

    @media(max-width: 575px) {
      font: 12px MontserratMedium, sans-serif;
    }

  }

  .address {
    height: 1.5rem;
  }

  .role-label {
    vertical-align: baseline;
    align-items: baseline;
    align-self: baseline;
    padding-top: 10px;
    flex-wrap: wrap;
    flex-grow: 0;
    overflow-wrap: break-word;
  }

  & h5 {
    text-align: right;
    font: 14px MontserratRegular, sans-serif;
    line-height: 17px;
    letter-spacing: -0.2px;
    color: $color-grey
  }

  & h6 {
    font: 16px MontserratBold, sans-serif;
    line-height: 20px;
    color: #2C2E36;
    margin-left: -2px;
  }
}

@media (max-width: $layout-breakpoint-small) {

  .interested-parties-body {
    margin: 0;
    padding: 0 10px;

    .interested-parties-name {
      font-size: 14px;
      padding: 0 10px;
    }
  }
}