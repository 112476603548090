@import "../../../variables.scss";

.webso-sidenav {
  background: linear-gradient(0deg, $color-deep-blue 0%, $blue-color 100%) !important;
  width: 300px;

  &-icon {
    background-position: center;
    background-repeat: no-repeat;
  }

  .sidenav-item,
  .sidenav-logo-block {
    height: 40px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.32);
    }
  }

  .sidenav---sidenav-nav---3tvij
    > .sidenav---sidenav-navitem---uwIJ-:hover
    > .sidenav---navitem---9uL5T::after,
  .sidenav---sidenav-nav---3tvij
    > .sidenav---sidenav-navitem---uwIJ-
    > .sidenav---navitem---9uL5T::after,
  .sidenav---sidenav-nav---3tvij
    > .sidenav---sidenav-navitem---uwIJ-:hover
    > .sidenav---navitem---9uL5T::after,
  .sidenav---sidenav-nav---3tvij
    > .sidenav---sidenav-navitem---uwIJ-
    > .sidenav---navitem---9uL5T::after {
    background-color: rgba(0, 0, 0, 0.32);
  }

  .sidenav-selected-item {
    background-color: rgba(0, 0, 0, 0.32);
  }

  /* sidenav title layout */
  .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T {
    line-height: 45px;
    height: 40px !important;
  }
  /* sidenav icon layout */
  .sidenav---navicon---3gCRo div,
  .sidenav---navicon---3gCRo span {
    height: 40px;
  }

  .styleCustomTitle {
    margin-bottom: 10px;
  }

  .badge.beta-badge {
    color: #fff !important;
    margin-left: 37px !important;
  }
  .webso-sidenav-icon {
    sup {
      top: -1em;
    }
  }

  .icon-homepage {
    background-image: url(../../../assets/img/sidenav/home.svg);
  }
  .icon-distribution-royalties {
    background-image: url(../../../assets/img/sidenav/distribution-royalties.svg);
  }
  .icon-privacy {
    background-image: url(../../../assets/img/sidenav/privacy.svg);
  }
  .icon-cookies {
    background-image: url(../../../assets/img/sidenav/cookies.svg);
  }
  .icon-check-events {
    background-image: url(../../../assets/img/sidenav/check-events.svg);
  }
  .icon-create-setlist {
    background-image: url(../../../assets/img/sidenav/create-setlist.svg);
  }
  .icon-check-setlists {
    background-image: url(../../../assets/img/sidenav/check-setlists.svg);
  }
  .icon-kpi {
    background-image: url(../../../assets/img/sidenav/kpi.svg);
  }
  .icon-advanced-search {
    background-image: url(../../../assets/img/sidenav/advanced-search-icon.svg);
  }
  &-logo {
    background-image: url(../../../assets/img/sidenav/sacem-logo-white2.svg),
      url(../../../assets/img/sidenav/partner-society-portal.svg);
    background-position:
      left bottom,
      right center !important;
    background-repeat: no-repeat, no-repeat;
    width: 165px !important;
    margin-left: 20px;
  }
}
