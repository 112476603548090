@import "../../../../variables.scss";

.primary-text {
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: MontserratRegular, sans-serif;
}
.basket-card {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
  border-radius: 6px 6px 0 0 !important;
  .header-card {
    padding: 15px 20px;
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    min-height: 50px;
    align-items: stretch;
    background: linear-gradient(90deg, rgba(161,193,247,1) 0%, rgba(47,117,238,1) 35%, rgba(67,50,133,1) 100%);
    .header-title {
      @extend .primary-text;
      font: 18px MontserratSemiBold, sans-serif;
      line-height: 1.5;
      margin: 0;
      color: $color-white;
    }

    .basket-work-list-header {
      display: flex;
      text-transform: uppercase;
      // margin: 15px;
      .works-badge {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 16px 13px 15px 35px;
        height: 30px;
        border-radius: 100px;
        cursor: pointer;
        background: url("/assets/img/icon-works-bleu.png") no-repeat 10px center $color-white;
        background-size: 25%;
        color: #0f1057;
      }

      @media (max-width: $layout-breakpoint-small) {
        display: none;
      }

      label {
        @extend .primary-text;
        font-size: 20px;
        font-weight: 600;
        color: $color-dark;
      }
    }
  }

  .content-card {
    color: $color-dark;
    padding: 20px !important;
    .basket-work-list {
      padding: 20px;
      border-radius: 6px;
      border: solid 1px #bdc2cc;
      max-height: 555px;
      overflow: auto;
      scrollbar-width: thin;

      .basket-work-list-header {
        display: flex;
        justify-content: flex-end;
        text-transform: uppercase;
        margin-bottom: 15px;

        @media (max-width: $layout-breakpoint-small) {
          display: none;
        }

        label {
          @extend .primary-text;
          font-size: 20px;
          font-weight: 600;
          color: $color-dark;
        }
      }
      .empty-content {
        font: 16px MontserratMedium, sans-serif;
        text-align: left;
        color: $color-grey;
      }
    }

    .work-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;

      .work-title {
        font: 16px MontserratSemiBold, sans-serif;
        line-height: 24px;
        width: auto;
        word-break: normal;
      }

      .btn-delete {
        border: none;
        width: 29px !important;
        height: 29px;
        background: url(/assets/img/icon-x-small.svg) center no-repeat;
      }
    }
  }

  .footer-card {
    background-color: $color-white !important;
    border-top: none !important;
    padding: 0 20px 20px 20px;
    Button {
      @extend .primary-text;
      font-family: MontserratMedium, sans-serif;
      font-size: 16px;
      padding: 6px 12px;
      border-radius: 4px;
      height: 36px;
    }
    .save-draft-button {
      border: solid 1px $color-grey;
      background-color: $color-white;
      color: $color-grey;
      margin-right: 10px;
      &:hover {
        color: #fff;
        background-color: #212529;
      }
      &:disabled {
        border: solid 1px $color-grey;
        background-color: $color-white;
        color: $color-grey;
      }
    }
    .validate-button {
      background-color: $color-light-bleu;
      color: $color-white;
      &:disabled {
        border: solid 1px $bg-btn-grey;
        background-color: $bg-btn-grey;
        opacity: 0.9;
        color: $color-text;
      }
    }
  }
}

@media (max-width: $layout-breakpoint-medium) {
  .basket-card {
    margin: 0;
    width: 100% !important;
    align-self: flex-start;
    padding: 0;
    min-height: 40px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);

    .header-card {
      width: auto;
      border-top-left-radius: 6px !important;
      border-top-right-radius: 6px !important;
      min-height: 40px;
      padding: 20px;

      .header-title {
        display: flex !important;
        flex-direction: row !important;
        width: 90%;
        justify-content: space-between;
        margin: 14px 0 14px 0;
        text-transform: uppercase;

        .title {
          width: auto;
        }
        .icons {
          display: flex;
          flex-direction: row;
          justify-content: right;
          align-items: center;
          .deploy-arrow-down {
            background: url("/assets/img/arrow-down-dark.svg") no-repeat;
            background-size: contain;
            width: 18px;
            height: 10px;
            margin-left: 10px;
            text-align: right;
          }
          .deploy-arrow-up {
            background: url("/assets/img/arrow-up-dark.svg") no-repeat;
            width: 18px;
            height: 10px;
            background-size: contain;
            margin-left: 10px;
          }
        }
      }
    }

    .content-card {
      height: auto;
    }
  }
}
