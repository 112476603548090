@import '../../variables.scss';

.works-badge {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 16px 15px 43px;
  height: 30px;
  border-radius: 100px;
  cursor: pointer;
  background: url(/assets/img/icon-works-white.svg) no-repeat 10px center #725fbf;
  .header-nbr-oeuvre {
    font: 16px MontserratSemiBold, sans-serif;
    background-color: #f8f9fa;
    border-radius: 100px;
    padding: 2px;
    color: $color-text;
    min-width: 23px;
    text-align: center;
  }
}