@import "../../variables.scss";

.input-duration {
  .input-error {
    color: $color-red;
    font-size: 14px;
  }

  .is-invalid {
    border: 1px solid $color-red;
  }
}