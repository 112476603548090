@import "../../variables.scss";

.bg-date{
  background: var(--background, #F7F7F2);
}
.p-3{
  flex-grow: 0;
  font-family: MontserratMedium, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #721c24;


}
.date-card {
  width: 624px;
  margin-top: 30px;
  margin-bottom: 50px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  .align-right {
    text-align: right;
  }
  .required {
    text-align: right;
    display: flex;
    justify-content: end;
    font-style: italic;
    font-size: 12px
  }
  .invalid {
    text-align: right;
    display: flex;
    justify-content: end;
    font-size: 14px;
    font-weight: bold;
    color:$color-red
  }
  .form-add-date {
    padding: 8px 5%;
    background: $color-white;
    color: $color-dark;
    label {
      font: 16px MontserratSemiBold, sans-serif;
      line-height: 24px;
      text-align: left;
    }
    .error-field {
      color: $color-red
    }
    .is-invalid {
      border: 1px solid $color-red;
    }
    .form-select {
      .form-control {
        border: none;
        padding: 0;
        &:focus {
          border: none !important;
          box-shadow: none !important;
        }
      }
    }
    .form-select, .form-control {
      font: 20px MontserratRegular, sans-serif;
      line-height: 30px;
    }


    .address-autocomplete {
      background: url(../../assets/img/search.svg) right 10px center no-repeat;
      padding-top: 8px; /* Push the placeholder text to the top by 8 pixels */

      #address {
        transform: translate3d(0px, 29.6px, 0px) !important;
        width: 100% !important;
      }

    }
    .rbt.address-autocomplete.form-select.is-invalid {
      background: none;
    }

    .btn-delete {
      border: none;
      width: 29px;
      height: 29px;
      background: url(../../assets/img/icon-trash.svg) center 10px no-repeat;
    }
  }
  .label-spacing {
    padding-right: 10px;
    padding-top: 8px;
  }
  .btn-secondary-add {
    height: 29px;
    padding: 4px 12px;
    border-radius: 4px;
    border: solid 1px #606060;
    background-color: $color-white;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: $color-grey;
  }
}
.container-btn-date {
  display: flex;
  justify-content: flex-end;
  button {
    text-align: center;
    border: none;
    font-size: 20px;
    font-weight: 500;
    padding: 8px 16px;
    border-radius: 4.8px;
    &:first-child {
      margin-right: 20px;
      border: none;
      color: $color-dark;
      border-radius: 4px;
      border: 1px solid var(--light-font, #606060);
      background: var(--transparent, rgba(255, 255, 255, 0.00));
      &:hover {
        box-shadow: 0 2px 4px #ccc;
        color: $color-dark;
        background: $color-wiki;
      }
    }
    &:last-child {
      background: var(--secondary-500, #725FBF);
      border-radius: 4px;
      color: $color-white;
      &:hover {
        box-shadow: 0 2px 3px #000;
        transform: translateY(1px);
      }
    }
  }
}
.form-select:focus, .form-control:focus {
  box-shadow: none !important;
  border-color: #ced4da !important;
}

.btn-primary:focus, .btn-primary:active:focus {
  box-shadow: none !important;
}
.modal-body {
  a {
    color: $color-primary;
    &:hover {
      color: $color-primary;
    }
  }
}

.no-programs {
  text-align: center;
  padding: 60px 0;
  p {
    font: 16px MontserratMedium, sans-serif;
    color: $color-dark;
  }

  a {
    text-decoration: none;
    color: $color-white;
    background: $color-primary;
    border-radius: 4px;
    padding: 6px 15px;
    font: 16px MontserratMedium, sans-serif;
    line-height: 24px;

    &:hover {
      text-decoration: none;
      color: $color-primary;
      background: $color-white;
      border: 1px solid $color-primary;
    }
  }
}

@media (max-width:624px) {
  .date-card {
    width: 100%;
    .card-title.h5 {
      font-size: 1rem;
    }
    .form-add-date {
      padding: 5px 0;
    }
  }
  .card {
    min-width: 100%;
  }
}
.case1{
  width:30% !important;
}
.case2{
  width:68% !important;
}
.header-card {
  background: var(--secondary-50, #F2F0F9);  color: $color-dark;
  font: 18px MontserratSemiBold, sans-serif;
  line-height: 27px;
  padding: 16px 30px;
  border-bottom: 1px solid $color-grey-light;

}
.date-card .form-add-date .is-invalid{
  border: 1px solid #bc204b !important;
}
.form-control{
  color: #212529!important;
}
.confirm-btn-valid{
  border-radius: 4px!important;
  background: var(--secondary-500, #725FBF)!important;
  font: 16px MontserratMedium, sans-serif;
  text-align: center;
}
