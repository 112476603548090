.KPIs-page-container {
  padding-left: 64px !important;
  padding-right: unset !important;

  .padding-top-unset {
    padding-top: unset !important;
  }

  .box-sizing {
    height: 440px;
  }
}
