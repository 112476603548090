$color-primary: #725FBF;
$color-primary-light: #9bbbf7;
$color-bg: #f7f7f2;
$color-dark: #3d4366;
$color-dark-title: #262a40;
$color-white: #fff;
$color-grey: #606060;
$color-grey-dark: #2c2e36;
$color-hub: #bc204b;
$color-text: #212529;
$color-deepKoamaru: #15167A;
$color-denim: #125FE2;
$color-jordyBlue: #A1C1F7;
$color-AthensGray: #e4e7eb;
$color-light-bleu: #2f75ee;
$color-deep-blue: #0C2F6E;
$color-bg-card: #ecf3fd;
$color-border-card: #bdc2cc;
$bg-btn-grey: #EBECEF;
$color-light-grey: #939392;
$color-red: #E01212FF;
$color-beige-light: #E7D4D1;
$color-hover-bleu: #0C3F97;
$color-header-bg: rgba(238, 238, 238, 0.55);
$color-card-header: #f2f0f9;
$color-events-purple: #7251A4;

$layout-breakpoint-small: 576px;
$layout-breakpoint-medium: 768px;
$blue-color: #125fe2;

$color-bg: #f0f3f8;
$color-dark: #2c2e36;
$color-white: #fff;
$color-grey: #606060;
$color-red: #bc204b;
$color-primary-red: #bc204b;
$color-secondary-red: #a41a3c;
$color-beige: #85716D;
$color-beige-light: #E7D4D1;
$color-wiki: #F0EFF0;
$color-grey-light: #bdc2cc;
$color-border-white: #f8f9fa;

$color-header-bg: rgba(238, 238, 238, 0.55);
$color-pink: #fdedef;
$color-border-pink: rgba(188, 32, 75, 0.5);


$inactivity-gray: rgb(171, 171, 171);
$color-error-background: #ffd5d5;
$color-error-text: #e75656;
$webso-box-section-background: #144AB8;
$webso-page-content-background: #e5eaf5;