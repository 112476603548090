@import "../../variables.scss";
@import "../../fonts";

.primary-title {
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: MontserratLight, sans-serif;
}
.img-icon {
  width: 30px;
  height: 30px;
}
.filter-bar {
  @extend .primary-title;
  min-width: 700px;
  background: $color-bg;
  display: flex;
  box-shadow: 0px 15px 10px -10px rgba(0, 0, 0, 0.135216);
  align-items: center;

  .filter-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .filter-col {
      display: flex;
      margin: 10px 5px;
      max-width: max-content;
    }
    .filter-title {
      font:
        16px MontserratSemiBold,
        sans-serif;
      line-height: 20px;
      margin-right: 5px;
      max-width: max-content;
      & span {
        margin-top: 3px;
      }
      .filter-searchbar {
        background: $color-white;
        border: 1px solid $color-grey;
        border-radius: 100px;
        padding: 4px 25px 4px 8px;
        font:
          14px MontserratRegular,
          sans-serif;
        line-height: 21px;
        color: $color-dark;
        max-width: 215px;
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
        &:focus-visible {
          outline: none;
        }
        option[value]:not([value=""]) {
          color: $color-dark;
        }
      }
      img.-icon-arrow {
        @extend .img-icon;
        margin-top: 8px;
        width: 12px;
        height: 9px;
      }
      img.-icon-back {
        @extend .img-icon;
        border: 1px solid $color-dark !important;
        border-radius: 5px;
        padding: 3px 0 3px 0;
        cursor: pointer;
      }
    }
  }

  .btn-search {
    width: 99px;
    height: 29px !important;
    font:
      14px MontserratMedium,
      sans-serif;
    border: solid 1px $color-dark !important;
    background: transparent;
    color: $color-dark !important;
    border-radius: 4px;
    text-align: center;
    &:focus {
      background: none !important;
    }
    &:hover {
      background: none !important;
    }
  }
  .divider {
    width: 2px;
    height: 25px;
    border-radius: 2px;
    background-color: #606060;
  }
  img.-icon-reinitialize {
    width: 29px;
    height: 30px;
    cursor: pointer;
  }
}
