@import "../../variables.scss";

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 1px;
  border-width: 1px -8px 0;
  border-top-color: #000;
}

.bs-popover-top[data-popper-placement^=top] {

  .popover-body {
    padding: 10px 15px !important;
    font: 14px MontserratRegular !important;
    line-height: 21px !important;
    border: 1px solid $color-dark;
    border-radius: 3px;
  }

  .popover-arrow::before {
    bottom: 1px;
    border-top-color: $color-dark;
  }

  .popover-arrow::after {
    bottom: 2px;
  }

}