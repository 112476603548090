.advanced-search-page-container {
  padding-left: 64px !important;
  padding-right: unset !important;
  height: auto !important;
  .padding-top-unset {
    padding-top: unset !important;
  }

  .box-sizing {
    height: 440px;
  }

  .table-pill {
    background: #363981 !important ;
  }

  .advanced-search-show-less-filters-btn {
    margin-left: auto;
    margin-right: 95px;
  }

  .failed-download {
    background-image: url("../../assets/img/advanced-search-icons/failed-operation-icon.svg");
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  .delete-button {
    background-image: url("../../assets/img/advanced-search-icons/delete-row-icon.svg");
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  .edit-button {
    background-image: url("../../assets/img/advanced-search-icons/edit-row-button.svg");
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  .download-button {
    background-image: url("../../assets/img/advanced-search-icons/download-export-icon.svg");
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  .too-many-rows-button {
    background-image: url("../../assets/img/advanced-search-icons/too-many-rows-icon.svg");
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  .no-rows-button {
    background-image: url("../../assets/img/advanced-search-icons/export-no-data.svg");
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  .status-tools-section {
    display: flex;
    justify-content: space-between;
    margin-right: 1vw;
  }

  .export-loading-box {
    display: flex;
    justify-content: center;
    align-content: center;

    > span {
      align-items: center;
    }
  }

  .export-loading-label {
    color: #563d7c;
    margin-left: 10px;
  }
}
