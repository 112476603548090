@import "../../../../variables.scss";
@import "../../../../fonts.sass";

.stepper-position {
  margin-left: -120px;
}
.stepper-container {
  display: flex;
  width: 100%;
  min-height: 0px;
  padding: 0px;
  justify-content: end;
  font-family: MontserratRegular, sans-serif;
  font-weight: bold;
  .stepper-block {
    display: flex;
    &:last-child {
      padding-right: 0;
      .stepper-item {
        margin-right: 0;
      }
    }
    &:first-child {
      padding-left: 0;
      margin-left: 0;
      .stepper-item {
        margin-left: 0;
        padding-left: 0;
      }
    }
    .stepper-item {
      width: 32px;
      height: 32px;
      margin: 0px auto;
      border-radius: 50%;
      text-align: center;
      font-size: 16px;
      color: $color-denim;
      display: block;
      border: 1px $color-light-bleu solid;
      cursor: pointer;
      background-color: $color-white;
      padding-top: 3px;
      &.active {
        border: none;
        background-color: $color-denim;
        color: $color-white;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0;
        padding-top: 4px;
      }
      &.step-done {
        background: url(/assets/img/icon-ok-white.svg) $color-denim no-repeat center;
      }
    }
  }
  .stepper-line {
    position: relative;
    top: 14px;
    height: 1px;
    border: 1px $color-primary dashed;
    width: 55px;
  }
}
