@import '../../../variables.scss';

.repertoire-performance-container {
    .repertoire-performance-value-box {
        border-radius: 15px;
        height: 100px;
        box-shadow: 0 4px 9px rgba(66, 89, 147, 0.25);
        display: flex;
        background: $webso-box-section-background;
        align-items: center;
        padding: 20px;
        justify-content: center;
    }

    .repertoire-performance-value-amount {
        color: $color-white;
        font-size: 30px;
        font-weight: 700;
    }

    .repertoire-performance-value-text {
        margin-left: 30px;
        color: $color-white;
        font-size: 20px;
        font-weight: 600;
    }

    .card-header {
        cursor: pointer;
    }

    .market-share-by-commercial-model-container {
        width: 100%;
        padding-block: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .open-arrow {
            transform: rotate(180deg);
        }

        .expand-arrow {
            flex-shrink: 0;
            width: var(--bs-accordion-btn-icon-width);
            height: var(--bs-accordion-btn-icon-width);
            margin-right: 20px;
            margin-top: 3px;
            content: '';
            background-image: var(--bs-accordion-btn-icon);
            background-repeat: no-repeat;
            background-size: var(--bs-accordion-btn-icon-width);
            transition: var(--bs-accordion-btn-icon-transition);
        }

        .bar-label {
            font-size: 18px;
            font-family: Klamp205Medium, sans-serif;
            color: #2c2e36;
            font-weight: bold;
        }

        .bar-amount {
            text-align: right;
            color: #606060;
            font-size: 18px;
            font-family: Klamp205Medium, sans-serif;
        }
    }
}
